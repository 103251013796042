/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  Center,
  Box,
  Text,
  Button,
  Loader,
  Group,
  Tooltip,
  Grid,
  createStyles,
  Card,
  Avatar,
  Tabs,
  Progress,
  Anchor,
  Select,
} from '@mantine/core';
import { recordTypes, roleInitialNoteMap } from '../constants';
import { useMedplum } from '@medplum/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';
import { getConfig } from '../config';
import {
  getSessionDetails,
  generateSummary,
  updateNote,
  downloadSummaryDetails,
  updateTranscript,
  saveTranscripts,
  completeUnknownPatientSession,
  deleteSessionAndNotes,
  getPractitionerRole,
  getTemplateListAccordingToRole,
  checkSubscriptionExpired,
  submitNoteFeedback,
} from '../utils/util';
import { toast } from 'react-toastify';
import { IconInfoCircle, IconMicrophone, IconCopy, IconMail, IconDownload } from '@tabler/icons-react';
import ConfirmBox from './ConfirmBox';
import { useAppContext } from '../AppProvider';
import EmailBox from './EmailBox';
import TemplateList from './TemplateList';
import PatientStepper from './PatientStepper';
import axios from '../utils/axios';
import UnknownSessionConfirmBox from '../patients/ConfirmBox';
import FeedbackRatingPopup from './FeedbackRatingPopup';
import { useMediaQuery } from '@mantine/hooks';
import RichTextEditorContainer, { useRichTextEditor } from './RichTextEditorContainer';
import ConfirmationModal from '../../../react/src/AppShell/ConfirmationModal';

const useStyles = createStyles((theme) => ({
  section: {
    padding: theme.spacing.md,
  },
}));

export const SessionDetails = () => {
  const medplum = useMedplum();
  const { classes } = useStyles();
  const location = useLocation();
  const routeState = location?.state || {};
  const { compositionId } = routeState;
  let { selectedRowCompositionId, generatedNote, selectedNoteId } = routeState;
  const navigate = useNavigate();
  const appointmentId = useParams().id;
  const [isStartRecordeing, setStartRecordeing] = useState(false);
  const [audioState, setAudioState] = useState(recordTypes.NOTSTARTED);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const intervalRef = useRef<any>(null);
  const [socket, setSocket] = useState<Socket | null>(null);
  const baseUrl = getConfig()?.baseUrl;
  const transcribeUrl = `${baseUrl}transcribe`;
  const [transcripts, setTranscripts] = useState<string[]>([]);
  const [summary, setSummary] = useState<any>(null);
  const [isUpdateTranscripts, setIsUpdateTranscripts] = useState(false);
  const [isSaveUpateClick, setIsSaveUpateClick] = useState(false);
  const [sessionDetails, setSessionDetails] = useState<any>({});
  const [isSummaryUpdated, setIsSummaryUpdated] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [audioUpdate, setAudioUpdate] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [openEmailBox, setOpenEmailBox] = useState(false);
  const {
    setIsSessionOn,
    isConfirmOpen,
    setIsConfirmOpen,
    redirectionUrl,
    setRedirectionUrl,
    setIsSummaryDetailsUpdated,
    isOldPatientId,
    setUnknownSessionsRefresh,
    hasSoapSummaryGenerationError,
    setHasSoapSummaryGenerationError,
    zoomTranscript,
    setIsSegmentedNote,
    isSegmentedNote,
    segmentedNoteTitle,
    setSegmentedNoteTitle,
    setTemplateListBtnDisabled,
    isTemplateListBtnDisabled,
    customTextareaRef,
    isNoteModified, setIsNoteModified, modifiedContent,
    setModifiedContent, noteLengthType, setNoteLengthType,
    isUpdatingSoapWhileCreatingTemplate, setIsUpdatingSoapWhileCreatingTemplate, isSessionOn,
    setIsProceedToTemplateNoteCreation, isRedirectAfterSavingSoapNote, setIsRedirectAfterSavingSoapNote,
    noteRegenerateLoader, setNoteRegenerateLoader, saveUnKnownSessionWhileRedirection, setSaveUnKnownSessionWhileRedirection,
    isSaveTranscripts, setIsSaveTranscripts, defaultCompositionId, setDefaultCompositionId,
  } = useAppContext();
  const [trascriptDocReferenceId, setTrascriptDocReferenceId] = useState<string | null>(null);
  // const [soapSummary, setSoapSummary] = useState('');
  const [summaryNote, setSummaryNotes] = useState('');
  const [selectedButton, setSelectedButton] = useState<string | null>(null);
  const [noteId, setNoteId] = useState('');
  const [isGeneratedTranscript, setIsGeneratedTranscript] = useState<boolean>(false);
  const [isConsentBox, setIsConsentBox] = useState<boolean>(false);
  const [consentBoxEvent, setConsentBoxEvent] = useState<string>('');
  const [isCheckLoader, setIsCheckLoader] = useState<boolean>(false);
  const [isCheckSession, setIsCheckSession] = useState(false);
  const [isCheckActionBtn, setIsCheckActionBtn] = useState(true);
  const [jsxRendered, setJsxRendered] = useState(false);
  const [isCheckLoaderTranscript, setIsCheckLoaderTranscript] = useState(false);
  const [isTabsLoad, setIsTabLoad] = useState(true);
  const [isCheckNoteGenerated, setIsCheckNoteGenerated] = useState(false);
  const [isAddPatient, setIsAddPatient] = useState(false);
  const [isCheckNextBtnLoader, setIsCheckNextBtnLoader] = useState<boolean>(false);
  const [defaultPatientEmail, setdefaultPatientEmail] = useState('');
  const [disableButtons, setDisableButtons] = useState(false);
  const [selectedTab, setSelectedTab] = useState('notedetails');
  const isMobile = useMediaQuery('(max-width: 769px)');

  const [unknownSessionConfirm, setUnknownSessionConfirm] = useState(false);
  const [isDeletingLoader, setIsDeletingLoader] = useState(false);
  const [templatesList, setTemplatesList] = useState<any[]>([]);

  const resourceID = medplum.getActiveLogin()?.profile?.reference;
  const parts: any = resourceID?.split('/');
  const practitionerId = parts?.[1];

  const [isNotesTabEnabled, setIsNotesTabEnabled] = useState(true);
  const [subject, setSubjective] = useState('');
  const [objective, setObjective] = useState('');
  const [assessment, setAssessment] = useState('');
  const [plan, setPlan] = useState('');
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isProgressBarVisible, setIsProgressBarVisible] = useState(false);
  const [popupOpened, setPopupOpened] = useState(false);
  const intervalSoap = useRef<any>(null);
  const startStopTranscriptionTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [emailBoxSoapSection, setEmailBoxSoapSection] = useState<string>('');
  const [emailBoxContent, setEmailBoxContent] = useState('');
  const [isCopyLoading, setIsCopyLoading] = useState(false);
  const [copiedSection, setCopiedSection] = useState<string | null>(null);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [downloadingSection, setDownloadingSection] = useState<string | null>(null);
  const [isSaveNotesClick, setIsSaveNotesClick] = useState(false);
  const isSaveNotesClickRef = useRef(isSaveNotesClick);
  const [isDownloadProceed, setDownloadProceed] = useState(false);
  const [isSoapSectionAvailable, setIsSoapSectionAvailable] = useState<string>('');
  const [isContentAvailable, setIsContentAvailable] = useState<string>('');
  const [isSoapNoteAvailable, setIsSoapNoteAvailable] = useState<boolean>(false);
  const [isNoteLengthSelected, setIsNoteLengthSelected] = useState<boolean>(false);
  const [prevNoteLengthType, setPrevNoteLengthType] = useState("");
  const [newCompositionId, setnewCompositionId] = useState<string>('');
  let sectionedData = {};

  //tab close event to update the transcript
  const transcriptsRef = useRef(transcripts);
  useEffect(() => {
    transcriptsRef.current = transcripts;
  }, [transcripts]);

  useEffect(() => {
    if (isSaveTranscripts || isStartRecordeing) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSaveTranscripts, isStartRecordeing]);

  //Check the current template tab value
  useEffect(() => {
    isSaveNotesClickRef.current = isSaveNotesClick;
  }, [isSaveNotesClick]);

  const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
    const token = await medplum.getAccessToken();
    const payload = {
      transcriptDocReferenceId: trascriptDocReferenceId,
      transcript: transcriptsRef.current,
    };
    const response = await axios.put(`/intg/update-transcript`, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    console.log(response)
  };

  useEffect(() => {
    const fetchCompositionId = async () => {
      try {
        const response = await getPractitionerRole(medplum, practitionerId);
        const role = response?.entry?.[0]?.resource?.code?.[0]?.coding?.[0]?.display || '';
        const templateResponse = await getTemplateListAccordingToRole(medplum, role);

        if (templateResponse?.entry?.length >= 0) {
          const defaultTitle = 'Medication Management';
          const title = roleInitialNoteMap[role] || defaultTitle;
          const entries = templateResponse.entry;
          setTemplatesList(entries);
          const entry = entries.find((entry: any) => entry.resource.title === title);
          setnewCompositionId(entry?.resource.id);
        } else {
          toast.error('Error generating SOAP notes from transcript.');
          console.error('Role is not assigned to any template: ', role);
        }
      } catch (error) {
        console.error('Error fetching template list:', error);
      }
    };

    fetchCompositionId();
  }, []);

  useEffect(() => {
    if (templatesList.length > 0) {
      getSession();
      return () => {
        clearInterval(intervalSoap.current);
      };
    }
    return () => { };
  }, [templatesList]);

  //Run useEffect if setIsNoteModified is set to true
  useEffect(() => {
    if (isNoteModified) {
      if (selectedButton && modifiedContent) {
        saveNoteGlobally(selectedButton);
      }
      setIsNoteModified(false);
      setModifiedContent('');
    }

    if (isRedirectAfterSavingSoapNote) {
      if (selectedButton) {
        saveNoteGlobally(selectedButton);
      }
      setIsRedirectAfterSavingSoapNote(false);
    }
  }, [isNoteModified, isRedirectAfterSavingSoapNote]);

  useEffect(() => {
    if (isUpdatingSoapWhileCreatingTemplate) {
      if (selectedButton) {
        saveNoteGlobally(selectedButton);
        setIsProceedToTemplateNoteCreation(true);
      }
      setIsUpdatingSoapWhileCreatingTemplate(false);
    }
  }, [isUpdatingSoapWhileCreatingTemplate]);

  //Run useEffect for download & save note
  useEffect(() => {
    if (isDownloadProceed) {
      handleDownloadAfterSave(isSoapSectionAvailable, isContentAvailable);
      setDownloadProceed(false);
    }
    if (isSoapNoteAvailable) {
      updateSummary();
      setIsSoapNoteAvailable(false);
      setAssessment('');
      setObjective('');
      setPlan('');
      setSubjective('');
    }

    setIsSoapSectionAvailable('');
    setIsContentAvailable('');
  }, [isDownloadProceed, isSoapNoteAvailable]);

  // trigger the unknown session transcript save process before redirection.  
  useEffect(() => {
    if (saveUnKnownSessionWhileRedirection) {
      handleTranscriptOnRedirection();
    }
  }, [saveUnKnownSessionWhileRedirection]);

  //Save transcript for unknown sessions when the browser page is closing
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if ((isSessionOn || isSaveTranscripts) && window.location.href.includes('/Appointment')) {
        const message = 'Are you sure you want to leave? Your changes may not be saved.';
        event.preventDefault();
        setSaveUnKnownSessionWhileRedirection(true);
        return message; // For some older browsers
      }
      return undefined;
    };
    if ((isSessionOn || isSaveTranscripts) && window.location.href.includes('/Appointment')) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSessionOn, isSaveTranscripts]);

  const getSession = (isNewTab: boolean = false, tabId: string = '') => {
    setIsTabLoad(false);
    getSessionDetails(medplum, appointmentId)
      .then((response: any) => {
        setTemplateListBtnDisabled(false);
        setdefaultPatientEmail(response?.patientEmail);
        setIsTabLoad(true);
        setIsCheckSession(true);
        setSessionDetails(response);
        setSummary(response?.soapSummary);
        setTranscripts(response?.transcriptData?.data?.transcripts);
        transcriptsRef.current = response?.transcriptData?.data?.transcripts;

        // Find the first item with sectionedData
        const sectionedDataItem = response?.soapSummary?.find(
          (item: any) => item.sectionedData
        );

        if (sectionedDataItem && Object.keys(sectionedDataItem.sectionedData).length) {
          const sectionedData = sectionedDataItem.sectionedData;
          let patientInformation = '';

          if (sectionedData['Patient Information']) {
            patientInformation = sectionedData['Patient Information'].replace(/<\/?b>/g, '').trim().replace(/\n/g, '<br>');
          }

          Object.keys(sectionedData).forEach((key) => {
            const value = sectionedData[key] || "";
            const cleanedValue = typeof value === 'string' ? value.replace(/<\/?b>/g, '').trim() : "";
            const formattedValue = cleanedValue.replace(/\n/g, '<br>');
            switch (key) {
              case 'Subjective':
                if (!formattedValue.includes('Patient Information')) {
                  setSubjective(`${patientInformation}<br>${formattedValue}`);
                } else {
                  setSubjective(formattedValue);
                }
                break;
              case 'Objective':
                setObjective(formattedValue);
                break;
              case 'Assessment':
                setAssessment(formattedValue);
                break;
              case 'Plan':
                setPlan(formattedValue);
                break;
              default:
                break;
            }
          });
        }

        if (response?.soapSummary?.length === 0 && hasSoapSummaryGenerationError) {
          updateGeneratedTranscript(response?.transcriptData?.transcriptDocId, appointmentId);
          return;
        } else {
          setHasSoapSummaryGenerationError(false);
        }
        if (generatedNote) {
          setSummaryNotes(generatedNote);
          setSelectedButton(selectedRowCompositionId);
        }
        else {
          setSummaryNotes(response?.soapSummary[0]?.notes);
        }
        const compositionNames = [] as Array<string>;
        for (let template of templatesList) {
          const code = template.resource?.type?.coding?.[0]?.code || '';
          if (code === 'visit-type') {
            compositionNames.push(template?.resource.title!);
          }
        }
        const visitType = response?.soapSummary?.filter((item: any) =>
          compositionNames.includes(item.compositionName)
        );
        if (!visitType || visitType.length === 0) {
          const entry = templatesList.find((entry: any) => entry.resource.title === (compositionNames[0]));
          visitType.push(entry);
        }
        if (compositionId) {
          setSelectedButton(compositionId);
        }

        if (isNewTab === true && !compositionId) {
          const noteId = response?.soapSummary?.find((item: any) => item.compositionId === tabId);
          setNoteId(noteId.noteId || tabId);
          setSummaryNotes(noteId?.notes);
        } else if (selectedRowCompositionId && generatedNote) {
          setSelectedButton(selectedRowCompositionId);
          const noteId = response?.soapSummary?.find((item: any) => item.compositionId === selectedRowCompositionId)?.noteId;
          setNoteId(noteId);
          const title = response?.soapSummary?.find((item: any) => item.compositionId === selectedRowCompositionId)?.compositionName;
          setSegmentedNoteTitle(title);
          setIsSegmentedNote(title?.includes("SOAP") ?? false);
          setSummaryNotes(generatedNote);
          routeState.selectedRowCompositionId = '';
          routeState.generatedNote = '';
        } else if (selectedNoteId) {
          handleFeedbackPageNoteSelection(response, selectedNoteId);
        } else {
          const selectedComposition = visitType[0] || response?.soapSummary[0];
          setSelectedButton(selectedComposition?.compositionId);
          setNoteLengthType(selectedComposition?.noteLength);

          const noteId = response?.soapSummary?.find((item: any) => item.compositionId === compositionId)?.noteId
            || response?.soapSummary[0]?.noteId;
          setNoteId(noteId);
          setSegmentedNoteTitle(selectedComposition?.compositionName);
          setIsSegmentedNote(selectedComposition?.compositionName?.includes("SOAP") ?? false);
        }

        setTrascriptDocReferenceId(response?.transcriptData.transcriptDocId);
        if (response?.soapSummary?.length === 0 && response?.patientnName !== "unknown") {
          //toast.info('Notes are being generated. Please check back in a bit. Click the Notes tab to refresh.');
          setIsNotesTabEnabled(false);
          setIsProgressBarVisible(true);
          setProgressPercentage(0);
          const progressInterval = setInterval(() => {
            setProgressPercentage(prev => {
              if (prev < 90) {
                return prev + 2;
              } else {
                clearInterval(progressInterval);
                return prev;
              }
            });
          }, 1000);

          const maxAttempts = 3;
          let attempts = 0;
          intervalSoap.current = setInterval(() => {
            attempts++;
            getSessionDetails(medplum, appointmentId).then((updatedResponse: any) => {
              if (updatedResponse?.soapSummary?.length > 0) {
                setHasSoapSummaryGenerationError(false);
                // Notes have been generated
                setSummary(updatedResponse.soapSummary);
                setSummaryNotes(updatedResponse.soapSummary[0]?.notes);
                setIsNotesTabEnabled(true);
                setProgressPercentage(100);
                getSession();

                // Hide progress bar after 5 seconds
                setTimeout(() => {
                  setIsProgressBarVisible(false);
                  clearInterval(intervalSoap.current);
                }, 5000);
              } else if (attempts >= maxAttempts || hasSoapSummaryGenerationError) {
                // Stop the interval after maxAttempts or error to generate notes
                clearInterval(intervalSoap.current);
                clearInterval(progressInterval);
                setIsNotesTabEnabled(false);
                setIsProgressBarVisible(true);
                setProgressPercentage(0);

                const reAttemptInterval = setInterval(() => {
                  setProgressPercentage(prev => {
                    if (prev < 90) {
                      return prev + 10;
                    } else {
                      clearInterval(reAttemptInterval);
                      return prev;
                    }
                  });
                }, 3000);
                updateGeneratedTranscript(updatedResponse?.transcriptData?.transcriptDocId, appointmentId);
              }
            });
          }, 6000);
        }
      })
      .catch((error: any) => {
        setTemplateListBtnDisabled(false);
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch session details.');
      });
  };

  const handleFeedbackPageNoteSelection = (response: any, selectedNoteId: string) => {
    if (!selectedNoteId) return;

    const extractedNoteId = selectedNoteId.split('/').pop();
    const selectedNoteResponse = response?.soapSummary?.find(
      (note: any) => note.noteId === extractedNoteId
    );

    if (!selectedNoteResponse) return;

    setSummaryNotes(selectedNoteResponse.notes);
    setSegmentedNoteTitle(selectedNoteResponse.compositionName);
    setSelectedButton(selectedNoteResponse.compositionId);
    setNoteLengthType(selectedNoteResponse.noteLength);
    setNoteId(selectedNoteResponse.noteId);

    routeState.selectedNoteId = '';
  };

  const startTranscription = async () => {
    // Added to check login session is expired or not
    try {
      await checkSubscriptionExpired(medplum);
    } catch (error) {
      toast.error('Your session has expired. Please sign-in again.');
      setTimeout(async () => {
        stopTranscription();
        await medplum.signOut();
        navigate('/signin');
        setIsSessionOn(false);
      }, 1500);
      return;
    }

    setIsSessionOn(true);
    const socket = io(transcribeUrl, { transports: ['polling'] });
    setSocket(socket);
    let stream: MediaStream | null = null;

    try {
      stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: false,  // Disable echo cancellation to better capture system audio
          noiseSuppression: false,  // Disable noise suppression to better capture system audio
          autoGainControl: true     // Keep auto gain control to adjust volume automatically
        }
      });

      if (!stream || stream.getAudioTracks().length === 0) {
        throw new Error('No audio tracks available');
      }

      setAudioState(recordTypes.STARTED);
      console.log(audioState);
      setStartRecordeing(true);

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      socket.on('can-open-mic', () => {
        console.log('Opening mic...');
        if (mediaRecorder.state === 'inactive') {
          mediaRecorder.start(2000);
        }
      });

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          socket.emit('microphone-stream', e.data);
        }
      };

      socket.on('close-mic', () => {
        console.log('Closing mic');
        if (mediaRecorder.state !== 'inactive') {
          mediaRecorder.stop();
        }
        socket.disconnect();
      });

      socket.on('transcript-result', (socketId, jsonFromServer) => {
        const result = jsonFromServer;
        if (result) {
          updateTranscripts(result);
        }
      });

      socket.on('disconnect', () => {
        console.log('Disconnected from server');
        if (mediaRecorder.state !== 'inactive') {
          mediaRecorder.stop();
        }
        socket.disconnect();
      });

      // Error handling for MediaRecorder
      mediaRecorder.addEventListener('error', (event) => {
        console.error('MediaRecorder error:', event);
        mediaRecorder.stop();
        if (mediaRecorder.stream) {
          mediaRecorder.stream.getTracks().forEach((track) => track.stop());
        }
      });

    } catch (error) {
      console.error('Error accessing microphone:', error);
      toast.error('Error accessing microphone. Retrying...');
      // Retry logic
      let retryCount = 0;
      const maxRetries = 5;

      const attemptGetUserMedia = async () => {
        try {
          retryCount++;
          stream = await navigator.mediaDevices.getUserMedia({
            audio: {
              echoCancellation: false,
              noiseSuppression: false,
              autoGainControl: true
            }
          });

          if (stream) {
            console.log('Microphone access granted after retry');
          }
        } catch (err) {
          console.error('Retry failed:', err);
          if (retryCount < maxRetries) {
            setTimeout(attemptGetUserMedia, 2000);
          } else {
            toast.error('Unable to access microphone after multiple attempts. Please check microphone permissions.');
          }
        }
      };

      if (retryCount < maxRetries) {
        attemptGetUserMedia();
      }
    }
  };


  const updateTranscripts = (transcript: string) => {
    setTranscripts((prevTranscripts) => {
      // Ensure `prevTranscripts` is always an array
      const safePrevTranscripts = Array.isArray(prevTranscripts) ? prevTranscripts : [];
      return [...safePrevTranscripts, transcript];
    });

    setAudioUpdate(true);
  };

  const stopTranscription = () => {
    setAudioState(recordTypes.FINISHED);
    startStopTranscriptionTimer(false);
    setStartRecordeing(false);
    setIsSessionOn(false);
    socket?.disconnect();
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      clearInterval(intervalRef.current);
      setStartRecordeing(false);

      // Access the stream and stop all tracks to disconnect the microphone
      const tracks = mediaRecorderRef.current.stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });

      if (transcripts?.length) {
        setIsUpdateTranscripts(true);
        setIsSummaryDetailsUpdated(true);
        setIsSaveTranscripts(true);
      }
    }
  };

  const transcriptData = transcripts?.length > 0 ? transcripts : (zoomTranscript?.length > 0 ? zoomTranscript : []);
  const getSummary = (data?: any) => {
    const payload = {
      compositionId: [data?.id],
      trascriptDocReferenceId: trascriptDocReferenceId,
      patientId: sessionDetails?.patientId,
      practitionerId: sessionDetails?.practitionerId,
      appointmentId: sessionDetails?.appointmentId,
      encounterId: sessionDetails?.encounterId,
      transcript: transcriptData,
      noteLength: noteLengthType || 'standard',
    };
    setSelectedButton(data?.resource?.id);
    setIsLoading(true);
    toast.success('Notes will be generated in the background.');
    generateSummary(medplum, payload)
      .then(() => {
        toast.success('Notes generated successfully.', { autoClose: 2000 });
        setNoteRegenerateLoader(false);
        setIsLoading(false);
        setIsUpdateTranscripts(false);
        setSelectedButton(data?.id);
        getSession(true, data.id);
        // setSoapSummary(response.data?.soapSummary?.notes);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.error('Error :', error);
        toast.error('Failed to generate the summary.');
      });
  };

  // save notes Button click
  const handleSaveNotesClick = () => {
    isSaveNotesClickRef.current = true;
    setIsSaveNotesClick(true);

    if (selectedButton) {
      saveNoteGlobally(selectedButton);
    }
  };

  // Save editor changes when clicking the save button or switching to other template tabs.
  const saveNoteGlobally = (noteId: string) => {
    let editorValue = '';
    if (modifiedContent && isNoteModified) {
      editorValue = modifiedContent;
    }
    else {
      editorValue = customTextareaRef?.current?.getEditorValue() || '';
    }

    let existingNoteObject = summary?.find((item: { compositionId: string; }) => {
      return item.compositionId === noteId;
    });

    if (editorValue === '' && existingNoteObject?.compositionName?.includes('SOAP')) {
      updateSectionedData(existingNoteObject);
    } else {
      if (existingNoteObject && existingNoteObject.notes !== editorValue) {
        const updatedSummary = summary?.map((item: { compositionId: string; }) => {
          return item.compositionId === noteId ? existingNoteObject : item;
        });
        setSummary(updatedSummary);
        updateSummary();
      } else if (isSaveNotesClickRef.current) {
        isSaveNotesClickRef.current = false;
        setIsSaveNotesClick(false);
        toast.info('No changes to be saved.');
      }
    }
  }

  const updateSectionedData = (existingNoteObject: any) => {
    let isUpdated = false;

    if (existingNoteObject.sectionedData?.Assessment !== assessment) {
      existingNoteObject.sectionedData.Assessment = assessment;
      isUpdated = true;
    }
    if (existingNoteObject.sectionedData?.Objective !== objective) {
      existingNoteObject.sectionedData.Objective = objective;
      isUpdated = true;
    }
    if (existingNoteObject.sectionedData?.Plan !== plan) {
      existingNoteObject.sectionedData.Plan = plan;
      isUpdated = true;
    }
    if (existingNoteObject.sectionedData?.Subjective !== subject) {
      existingNoteObject.sectionedData.Subjective = subject;
      isUpdated = true;
    }

    if (isUpdated) {
      const updatedSummary = summary?.map((item: { compositionId: string }) => {
        return item.compositionId === existingNoteObject.compositionId ? existingNoteObject : item;
      });
      setSummary(updatedSummary);
      updateSummary();
    }
  };

  const getSectionedData = (updatedNote: string) => {
    if (segmentedNoteTitle && segmentedNoteTitle.includes("SOAP")) {
      sectionedData = {
        Subjective: subject,
        Objective: objective,
        Assessment: assessment,
        Plan: plan,
      };

      if (updatedNote === '') {
        updatedNote = '';

        if (subject) {
          updatedNote += 'Subjective' + '\n' + subject + '\n';
        }
        if (objective) {
          updatedNote += 'Objective' + '\n' + objective + '\n';
        }
        if (assessment) {
          updatedNote += 'Assessment' + '\n' + assessment + '\n';
        }
        if (plan) {
          updatedNote += 'Plan' + '\n' + plan + '\n';
        }
      }
    }

    return { updatedNote, sectionedData };
  };

  // Save the note function
  const updateSummary = () => {
    let updatedNote = modifiedContent || customTextareaRef?.current?.getEditorValue() || '';
    const { updatedNote: newUpdatedNote, sectionedData } = getSectionedData(updatedNote);
    updatedNote = newUpdatedNote;

    const payload = {
      noteId: noteId,
      updatedNote: updatedNote,
      ...(segmentedNoteTitle.includes("SOAP") && { sectionedData: sectionedData }),
    };

    setIsLoading(true);
    updateNote(medplum, payload)
      .then(async (response) => {
        setIsLoading(false);
        toast.success(`${segmentedNoteTitle} note saved successfully.`);
        setIsSummaryUpdated(false);
        setIsSummaryDetailsUpdated(false);

        setSummary((prevSummary: any[]) =>
          prevSummary.map((item) => {
            if (item.noteId === noteId) {
              const updatedItem = { ...item, notes: updatedNote };
              if (modifiedContent || isSaveNotesClickRef.current) {
                setSummaryNotes(updatedItem.notes);
                setIsSaveNotesClick(false);
                isSaveNotesClickRef.current = false;
              }
              return updatedItem;
            }
            return item;
          })
        );

        // Update the editor content with the latest notes
        setSubjective(subject);
        setObjective(objective);
        setAssessment(assessment);
        setPlan(plan);
        setIsSaveNotesClick(false);

      })
      .catch((error: Error) => {
        setIsLoading(false);
        console.error('Error on updating Note:', error);
        toast.error('Failed to update the Note.');
      });
  };

  useEffect(() => {
    const divElement = document.getElementById('transcripts_data');

    if (jsxRendered && Array.isArray(transcripts) && divElement) {
      const renderTranscripts = () => {
        if (!divElement) return;

        // Save cursor position
        const selection = window.getSelection();
        const range = selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : null;

        // Render transcripts only if the content has changed
        const currentContent = divElement.innerText || divElement.textContent;
        const newContent = transcripts.join('\n');
        if (currentContent !== newContent) {
          divElement.innerHTML = transcripts
            .map((item) => `<div class="transcripts_item">${item}</div>`)
            .join('');
        }

        // Restore cursor position
        if (range) {
          selection?.removeAllRanges();
          selection?.addRange(range);
        }

        setAudioUpdate(false);
      };

      // Initial render
      renderTranscripts();

      // Handle scrolling and resizing
      const handleInteraction = () => {
        // Re-render transcripts when scrolling or resizing
        setTimeout(renderTranscripts, 0);
      };

      window.addEventListener('scroll', handleInteraction);
      window.addEventListener('resize', handleInteraction);

      return () => {
        window.removeEventListener('scroll', handleInteraction);
        window.removeEventListener('resize', handleInteraction);
      };
    }
    return () => { };
  }, [audioUpdate, jsxRendered, transcripts]);



  const handleDivInput = (event: React.FormEvent<HTMLDivElement>) => {
    const text = event.currentTarget.innerText || event.currentTarget.textContent;
    const contentArray = text?.split('\n') || [];
    setIsUpdateTranscripts(true);
    setIsSaveTranscripts(true);
    setIsSummaryDetailsUpdated(true);
    setTranscripts(contentArray);
  };

  const downloadSummary = (soapSection?: string, content?: string) => {
    setIsDownloading(true);

    if (soapSection && content) {
      setIsSoapSectionAvailable(soapSection);
      setIsContentAvailable(content);

      switch (soapSection) {
        case 'Assessment':
          setAssessment(content);
          break;
        case 'Objective':
          setObjective(content);
          break;
        case 'Plan':
          setPlan(content);
          break;
        case 'Subjective':
          setSubjective(content);
          break;
        default:
          break;
      }

      setIsSoapNoteAvailable(true);
      handleDownloadAfterSave(soapSection, content);
    }
    else if (window.location.href.includes('/Appointment') && content !== '' && soapSection == '') {//for soap entire note download
      setModifiedContent(content || '');
      setIsNoteModified(true);
      setDownloadProceed(true);
    } else if (window.location.href.includes('/Appointment') && customTextareaRef.current?.getEditorValue()) {
      setModifiedContent(customTextareaRef?.current?.getEditorValue());
      setIsNoteModified(true);
      setDownloadProceed(true);
    }
    else {
      if (selectedButton) {
        saveNoteGlobally(selectedButton);
      }
      setDownloadProceed(true);
    }
  };

  const handleDownloadAfterSave = (isSoapSectionAvailable: string, isContentAvailable: string) => {
    if (noteId) {
      downloadSummaryDetails(medplum, noteId, isContentAvailable ? isContentAvailable : modifiedContent ?? summaryNote, isSoapSectionAvailable || "",)
        .then((response: any) => {
          setDownloadingSection(null);
          if (response.data) {
            const filename = response?.headers['content-disposition']?.split('filename=')?.[1];
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${filename}`;
            a.click();
            window.URL.revokeObjectURL(url);
            setIsDownloading(false);
            toast.success('Notes Downloaded Successfully.');
          }
        })
        .catch((error: any) => {
          setIsDownloading(false);
          setDownloadingSection(null);
          console.error('Error :', error);
          toast.error('Download failed.');
        });
    }
  }

  const onConfirmExit = async () => {
    stopTranscription();
    setTranscripts([]);
    setIsSummaryDetailsUpdated(false);

    if (redirectionUrl) {
      if (redirectionUrl === '/signin') {
        await medplum.signOut();
        setIsConfirmOpen(false);
        navigate(redirectionUrl);
        setRedirectionUrl(undefined);
      } else {
        setIsConfirmOpen(false);
        navigate(redirectionUrl);
        setRedirectionUrl(undefined);
      }
    } else {
      setIsConfirmOpen(false);
      navigate('/session');
      setRedirectionUrl(undefined);
    }
  };

  const copyDetails = (summary: string) => () => {
    setIsCopied(true);
    const plainText = summary
      .replace(/<br\s*\/?>/gi, '\n')
      .replace(/<\/?[^>]+(>|$)/g, "");
    navigator.clipboard
      .writeText(plainText)
      .then(() => {
        console.log('Copied to clipboard');
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((error) => {
        console.error('Error on copying:', error);
        setIsCopied(false);
      });
  };

  const handleTemplateTabClick = (data: any) => {
    setIsSaveNotesClick(false);
    isSaveNotesClickRef.current = false;
    if (selectedButton) {
      saveNoteGlobally(selectedButton);
    }
    setIsSegmentedNote(false);
    setSummaryNotes(data.notes);
    setSelectedButton(data.compositionId);
    setNoteLengthType(data.noteLength || 'standard');
    setNoteId(data.noteId);
    setSegmentedNoteTitle(data.compositionName);
  };

  const updateGeneratedTranscript = (transcriptId?: any, appointmentId?: any) => {
    //adding check means updating transcript only if notes are not generated
    //and trying to reattempt to generate notes
    if (!transcriptsRef.current || (Array.isArray(transcriptsRef.current) && transcriptsRef.current.every(item => item.trim() === ""))) {
      toast.error("Transcript is empty. Please add content before saving & updating the note.");
      return;
    }

    setIsSaveUpateClick(true);
    startStopTranscriptionTimer(false);
    toast.info('Notes are being generated. Please wait...');
    const payload = {
      transcriptDocReferenceId: trascriptDocReferenceId ?? transcriptId,
      transcript: transcriptsRef.current,
      appointmentId: sessionDetails?.appointmentId ?? appointmentId,
      noteLength: sessionDetails?.noteLength,
    };

    setIsCheckLoader(true);
    setIsGeneratedTranscript(false)
    setIsCheckNoteGenerated(true);
    updateTranscript(medplum, payload)
      .then((response: any) => {
        setIsSaveUpateClick(false);
        setIsCheckLoader(false);
        setIsUpdateTranscripts(false);
        setIsConfirmOpen(false);
        setIsSessionOn(false);
        setIsSaveTranscripts(false);
        setIsSummaryDetailsUpdated(false);
        setIsCheckNoteGenerated(false);
        setIsProgressBarVisible(false);
        setProgressPercentage(0);
        if (response?.note?.length > 0) {
          toast.success(response.message, { autoClose: 2000 });
          getSession();
        } else {
          toast.error(`Note generation was unsuccessful. Please use 'Save & Update Notes' button to regenerate the note again.`);
        }
      })
      .catch((error: any) => {
        const errorMessage = error?.response?.status === 503 && error?.response?.data
          ? error.response.data
          : 'Note generation was unsuccessful. Please try to update transcript again.';
        toast.error(errorMessage, { autoClose: 30000 });

        setIsProgressBarVisible(false);
        setIsUpdateTranscripts(false);
        setIsGeneratedTranscript(false);
        setIsCheckLoader(false);
      });
  };

  const handleClickTabs = (value: string) => {
    setSelectedTab(value);
    //setSelectedButton(null);
    setIsCheckActionBtn(value === 'notedetails');
    if (window.location.href.includes('/Appointment') && selectedTab === 'transcript' && (isSessionOn || isSaveTranscripts)) {
      setSaveUnKnownSessionWhileRedirection(true); //save the unknown session transcript before redirection
    } else if (selectedTab === 'notedetails' && !isCheckNoteGenerated && window.location.href.includes('/Appointment') && customTextareaRef.current?.getEditorValue()) {
      setModifiedContent(customTextareaRef?.current?.getEditorValue());
      setIsNoteModified(true);
    } else if (isCheckNoteGenerated && value === 'notedetails' && sessionDetails?.patientnName !== "unknown") {
      //toast.success('Notes are being generated. Please check back in a bit. Click the Notes tab to refresh.');
      setIsNotesTabEnabled(false);
      setIsProgressBarVisible(true);
      setProgressPercentage(0);
      const progressInterval = setInterval(() => {
        setProgressPercentage(prev => {
          if (prev < 90) {
            return prev + 10;
          } else {
            clearInterval(progressInterval);
            return prev;
          }
        });
      }, 1000);

      const maxAttempts = 2
      let attempts = 0;
      const checkNotesInterval = setInterval(() => {
        attempts++;
        getSessionDetails(medplum, appointmentId).then((updatedResponse: any) => {
          if (updatedResponse?.soapSummary?.length > 0) {
            // Notes have been generated
            setSummary(updatedResponse.soapSummary);
            setSummaryNotes(updatedResponse.soapSummary[0]?.notes);
            setIsNotesTabEnabled(true);
            setProgressPercentage(100);

            // Hide progress bar after 5 seconds
            setTimeout(() => {
              setIsProgressBarVisible(false);
              clearInterval(checkNotesInterval);
            }, 5000);
          } else if (attempts >= maxAttempts) {
            // Stop the interval after maxAttempts
            clearInterval(checkNotesInterval);
          }
        });
      }, 5000);

    }
  };

  const parseHTMLToPlainText = (html: string) => {
    return html?.replace(/<\/?b>/g, '');
  }

  const saveTranscript = async () => {
    const filteredTranscripts = (transcriptsRef.current || transcripts)?.filter((t) => t.trim() !== "");

    if (audioState === 'started') {
      startStopTranscriptionTimer(false);
      stopTranscription();
    }

    // Check if filteredTranscripts is empty
    if (!filteredTranscripts || filteredTranscripts.length === 0) {
      toast.error("Transcript is empty. Please add content before saving.", { autoClose: 2000 });
      return;
    }

    const payload = {
      transcriptDocReferenceId: trascriptDocReferenceId,
      transcript: transcriptsRef.current || transcripts,
      appointmentId: appointmentId,
      patientName: sessionDetails.patientnName,
      practitionerName: sessionDetails.practitionerName,

    };
    setIsCheckLoaderTranscript(true);
    await saveTranscripts(medplum, payload).then((response) => {
      setIsSaveTranscripts(false);
      setIsCheckLoaderTranscript(false);
      setIsConfirmOpen(false);
      setIsSessionOn(false);
      setIsSummaryDetailsUpdated(false);
      toast.success(response.message, { autoClose: 2000 });
    }).catch((error) => {
      console.log(error);
      setIsCheckLoaderTranscript(false);
      setIsSaveTranscripts(false);
      setIsConfirmOpen(false);
      setIsSessionOn(false);
      setIsSummaryDetailsUpdated(false);
    });
  }

  //Save transcript for unknown sessions
  const handleTranscriptOnRedirection = async () => {
    const validTranscripts = transcripts.filter(t => t.trim() !== "");

    if (validTranscripts.length === 0) {
      setIsSaveTranscripts(false);
      return;
    }

    const transcriptsData = sessionDetails?.transcriptData?.data.transcripts ?? [];
    const shouldSaveTranscript = JSON.stringify(transcripts) !== JSON.stringify(transcriptsData);


    startStopTranscriptionTimer(false);
    stopTranscription();
    setSaveUnKnownSessionWhileRedirection(false);

    if (shouldSaveTranscript) {
      saveTranscript();
    }
    else {
      setIsSaveTranscripts(false);
    }

    if (redirectionUrl) {
      navigate(redirectionUrl);
      setRedirectionUrl(undefined);
    }
  };

  const handleEndSessionClick = () => {
    const transcriptsData = transcripts ?? sessionDetails?.transcriptData?.data ?? [];

    if (!transcriptsData || (typeof transcriptsData === "object" && Object.keys(transcriptsData).length === 0) || (Array.isArray(transcriptsData) && transcriptsData.length === 0)
      || (Array.isArray(transcriptsData) && transcriptsData.every((t) => t.trim() === ""))) {
      toast.error("Please record more before completing the session.");
      return;
    }
    // Without saving the unknown session transcript, then calling 
    if (isSaveTranscripts) {
      setSaveUnKnownSessionWhileRedirection(true);
    }

    setIsAddPatient(true);
    setDisableButtons(true);
  };

  const onCancel = () => {
    setIsAddPatient(false);
  };

  const getSessionResp = (appointmentId: string) => {
    getSessionDetails(medplum, appointmentId)
      .then((response: any) => {
        // Generate a note
        generateNoteFromSession(response);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch session details.');
      });
  };

  const generateNoteFromSession = (response?: any) => {
    const id = defaultCompositionId && defaultCompositionId.trim() !== ""
      ? defaultCompositionId : newCompositionId;

    const payload = {
      compositionId: [id],
      trascriptDocReferenceId: response?.transcriptData?.transcriptDocId,
      patientId: response?.patientId,
      practitionerId: response?.practitionerId,
      appointmentId: response?.appointmentId,
      encounterId: response?.encounterId,
      transcript: transcripts,
      noteLength: noteLengthType,
    };
    generateSummary(medplum, payload)
      .then(() => {
        setIsCheckNextBtnLoader(false);
        setnewCompositionId('');
        setDefaultCompositionId('');
      })
      .catch((error: any) => {
        console.error('Error :', error);
        toast.error('Failed to generate the summary.');
      });
  }

  const endUnknownSession = async (patientData: any) => {
    setIsCheckNextBtnLoader(true);
    let navigateUrl: string | undefined;
    let sessionTranscripts = transcripts ?? sessionDetails?.transcriptData?.data?.transcripts ?? [];
    if (!sessionTranscripts || (typeof sessionTranscripts === "object" && Object.keys(sessionTranscripts).length === 0) || (Array.isArray(sessionTranscripts) && sessionTranscripts.length === 0)) {
      toast.info("Please record more before completing the session.");
      setIsCheckNextBtnLoader(false);
      setIsAddPatient(false);
      setIsCheckNextBtnLoader(false);
      setDisableButtons(false);
      return;
    }

    const payload = {
      appointmentId,
      patientName: patientData.patientDetails?.patientName,
      birthDate: patientData.patientDetails?.birthDate,
      gender: patientData?.patientDetails?.gender,
      transcripts: sessionTranscripts,
      mentalHealthDiagnoses: patientData?.patientDetails?.mentalHealthDiagnoses,
      otherDiagnoses: patientData?.patientDetails?.otherDiagnoses,
      patientId: isOldPatientId,
    };

    try {
      const response = await completeUnknownPatientSession(medplum, payload);
      setIsAddPatient(false);
      setIsCheckNextBtnLoader(false);
      if (response) {
        navigateUrl = `/Appointment/${response.sessionId}/details`;
        // Trigger note generation because the generate note function is called inside getSessionResp.
        getSessionResp(response.sessionId);
        toast.success('Session completed successfully.');
        setTimeout(() => {
          if (navigateUrl) {
            navigate(navigateUrl);
          } else {
            navigate('/session');
          }
          window.location.reload();
        }, 1000);
      } else {
        toast.error('Notes will not be generated.', {
          onClose: () => navigate('/session')
        });
      }
    } catch (error) {
      toast.error('Error in completing the session. Please try again.');
      setIsCheckNextBtnLoader(false);
      setIsAddPatient(false);
      console.error('API Error:', error);
    }
  };

  const handleConfirmDelete = async () => {
    setIsDeletingLoader(true);
    setDisableButtons(true);
    setUnknownSessionsRefresh(false);
    try {
      const path = window.location.pathname;
      const id = path.split('/').slice(-2, -1)[0];

      if (!id) {
        throw new Error('ID not found in URL');
      }
      const deleteResponse = deleteSessionAndNotes;
      deleteResponse(medplum, id)
        .then((response) => {
          if (response) {
            toast.success(`Session Deleted successfully!`, {
              position: toast.POSITION.TOP_RIGHT
            });
            setTimeout(() => {
              setDisableButtons(false);
              navigate('/session');
            }, 2000);
            setUnknownSessionsRefresh(true);
            setIsDeletingLoader(false);
            setUnknownSessionConfirm(false);
          }
        })
        .catch((error) => {
          console.error('Error deleting session:', error);
          toast.error(`Failed to delete the session`, {
            position: toast.POSITION.TOP_RIGHT
          });
          setDisableButtons(false);
          setIsDeletingLoader(false);
          setUnknownSessionConfirm(false);
        });
    } catch (error) {
      console.error(`Error deleting session:`, error);
      toast.error(`Failed to delete the session`, {
        position: toast.POSITION.TOP_RIGHT
      });
      setIsDeletingLoader(false);
    }
  };

  // const handleRegenerateClick = () => {
  //   const data = { 'id': selectedButton };
  //   getSummary(data);
  // };

  const handleFeedbackClick = () => {
    setPopupOpened(true);
  };

  const handlePopupClose = () => {
    setPopupOpened(false);
  };

  const handlePopupSubmit = async (feedback: string[], comment: string, rating: string) => {
    const payload = {
      noteId: noteId,
      rating: rating,
      comment: feedback + (comment ? `: ${comment}` : ''),
    };

    try {
      await submitNoteFeedback(medplum, payload);
      toast.success('Feedback submitted successfully');
    } catch (error) {
      toast.error('Error submitting feedback');
    }
  };

  // Set up a timer to save transcription periodically every 30 minutes
  function startStopTranscriptionTimer(isStart: boolean) {
    if (isStart) {
      if (startStopTranscriptionTimerRef.current) {
        clearInterval(startStopTranscriptionTimerRef.current);
        startStopTranscriptionTimerRef.current = null;
      }

      startStopTranscriptionTimerRef.current = setInterval(async () => {
        console.log('Saving transcription...');
        console.log(transcripts);
        await saveTranscript();
        //added to check if the login session is expired
        await isLoginSessionExpired();
      }, 10 * 60 * 1000);
    } else {
      if (startStopTranscriptionTimerRef.current) {
        clearInterval(startStopTranscriptionTimerRef.current);
        startStopTranscriptionTimerRef.current = null;
      }
    }
  }

  const isLoginSessionExpired = async () => {
    try {
      await checkSubscriptionExpired(medplum);
    } catch (error) {
      if (!transcripts?.length) {
        await saveTranscript();
      }
      toast.error('Your session has expired. Please sign-in again.');
      setTimeout(async () => {
        stopTranscription();
        await medplum.signOut();
        navigate('/signin');
        setIsSessionOn(false);
      }, 1500);
      return;
    }
  };

  //entire SOAP section copy , download and send email functionality
  const handleSectCopy = async (sectionedData: any, section: string) => {
    setIsCopyLoading(true);
    try {
      const { Subjective, Objective, Assessment, Plan } = sectionedData;
      const combinedContent = `
Subjective
${Subjective || ''}
\nObjective
${Objective || ''}
\nAssessment
${Assessment || ''}
\nPlan
${Plan || ''}
        `;
      const plainText = combinedContent
        .replace(/<br\s*\/?>/gi, '\n')
        .replace(/<\/?[^>]+(>|$)/g, "");
      await navigator.clipboard.writeText(plainText);
      setCopiedSection('all');
      setTimeout(() => setCopiedSection(null), 2000);
    } finally {
      setIsCopyLoading(false);
    }
  };

  const handleDownloadEntireNote = async () => {
    const entireNoteContent = `
      Subjective \n ${subject} \n
      Objective \n ${objective} \n
      Assessment \n ${assessment} \n
      Plan \n ${plan} \n
    `;
    setIsDownloading(true);
    setDownloadingSection("SOAP Download");
    downloadSummary("", entireNoteContent);
  };

  const handleSendEntireNoteEmail = async () => {
    const entireNoteContent = `
Subjective\n ${subject}\n
Objective\n ${objective}\n
Assessment\n ${assessment}\n
Plan\n ${plan}
    `;
    //Edited note content to save using the saveNoteGlobally function.
    if (selectedButton) {
      setIsSaveNotesClick(true);
      saveNoteGlobally(selectedButton);
    }

    setIsConsentBox(true);
    setConsentBoxEvent(`email||${entireNoteContent}`);
  };
  //Individual Sections
  const subjectEditor = useRichTextEditor(subject, setSubjective, setIsSummaryUpdated);
  const objectiveEditor = useRichTextEditor(objective, setObjective, setIsSummaryUpdated);
  const assessmentEditor = useRichTextEditor(assessment, setAssessment, setIsSummaryUpdated);
  const planEditor = useRichTextEditor(plan, setPlan, setIsSummaryUpdated);

  const handleEmailClick = (label: string, content: string) => {
    //Edited segmented content to save using the saveNoteGlobally function.
    if (selectedButton) {
      setIsSaveNotesClick(true);
      saveNoteGlobally(selectedButton);
    }
    setIsConsentBox(true);
    setEmailBoxSoapSection(label);
    setConsentBoxEvent(`email|${label}|${content}`);
    setEmailBoxContent(content);
  };

  const handleCopy = async (value: string, section: string) => {
    setIsCopyLoading(true);
    try {
      const plainText = value.replace(/<br\s*\/?>/gi, '\n').replace(/<\/?[^>]+(>|$)/g, "");
      await navigator.clipboard.writeText(plainText);
      setCopiedSection(section);
      setTimeout(() => setCopiedSection(null), 2000);
    } finally {
      setIsCopyLoading(false);
    }
  };

  const handleDownload = async (label: string, content: string) => {
    setIsDownloadLoading(true);
    try {
      setDownloadingSection(label);
      downloadSummary(label, content);
    } finally {
      setIsDownloadLoading(false);
    }
  };

  //Complete Note for non segmented notes
  const CustomTextarea = forwardRef(({
    label,
    initialValue,
    isCheckActionBtn,
    copyDetails,
    isloading,
    isCopied,
    isDownloading,
    downloadSummary,
    setIsConsentBox,
    setConsentBoxEvent,
  }: {
    label: string;
    initialValue: string;
    isCheckActionBtn: boolean;
    copyDetails: (value: string) => () => void;
    isloading: boolean;
    isCopied: boolean;
    isDownloading: boolean;
    isEmailLoading: boolean;
    downloadSummary: () => void;
    setIsConsentBox: (value: boolean) => void;
    setConsentBoxEvent: (event: string) => void;
  }, ref: any) => {
    const [internalValue, setInternalValue] = useState(initialValue);

    const formattedValue = (internalValue || "").replace(/\n/g, '<br>');

    const editor = useRichTextEditor(formattedValue, setInternalValue, setIsSummaryUpdated);

    useEffect(() => {
      setInternalValue(initialValue);
    }, [initialValue]);

    // Expose the internalValue to the parent component
    useImperativeHandle(ref, () => ({
      getEditorValue: () => internalValue,
    }));

    useEffect(() => {
      if (editor) {
        editor.setEditable(true);
      }
    }, [editor]);

    return (
      <RichTextEditorContainer
        label={label}
        value={internalValue}
        editor={editor}
        isCheckActionBtn={isCheckActionBtn}
        section={label}
        handleCopy={copyDetails(internalValue)}
        handleDownload={downloadSummary}
        copiedSection={isCopied ? label : null}
        isCopyLoading={isProgressBarVisible || isloading}
        downloadingSection={isDownloading ? label : null}
        isDownloadLoading={isDownloading || isProgressBarVisible || isloading}
        isEmailLoading={isProgressBarVisible || isloading}
        handleEmailClick={(label) => {
          //Edited note content to save using the saveNoteGlobally function.
          if (selectedButton) {
            setIsSaveNotesClick(true);
            saveNoteGlobally(selectedButton);
          }

          setIsConsentBox(true);
          setConsentBoxEvent('email');
        }}
      />
    );
  });

  //note length dropdown component
  const renderNoteLengthSelect = () => (
    <Grid.Col span={12} md={2} mt={20}>
      <Select
        data={[
          { value: 'concise', label: 'Concise' },
          { value: 'standard', label: 'Standard' },
          { value: 'detailed', label: 'Detailed' },
        ]}
        label={
          <div className='note-length-label'>
            Note Length
            <Tooltip
              label="Choose the length of the note. The selected note will be regenerated based on the selected length."
              position="top"
              withArrow
              sx={{ fontSize: '12px' }}
            >
              <IconInfoCircle color='#444444d9' size={18} className='note-length-info-icon' />
            </Tooltip>
          </div>
        }
        placeholder="Select note length"
        value={noteLengthType}
        disabled={noteRegenerateLoader || isProgressBarVisible || isloading}
        onChange={handleNoteLengthChange}
        defaultValue="standard"
        clearable={false}
      />
    </Grid.Col>
  );

  const handleNoteLengthChange = (value: string | null) => {
    setPrevNoteLengthType(noteLengthType);
    setNoteLengthType(value ?? 'standard');
    setIsNoteLengthSelected(true);
  };

  const handleCloseModal = () => {
    setIsNoteLengthSelected(false);
    setNoteLengthType(prevNoteLengthType);
  };

  const handleNoteRegenerationBasedOnLength = () => {
    setNoteRegenerateLoader(true);
    setPrevNoteLengthType(noteLengthType);
    setIsNoteLengthSelected(false);

    const data = { 'id': selectedButton };
    getSummary(data);
  }

  return (
    <>
      <Box px="lg" pb="sm">
        <Grid>
          <Grid.Col span={12} md={12} mt={8}>
            <Card
              shadow="sm"
              sx={{ overflow: 'auto', border: '1px solid #ebebeb', borderRadius: '10px' }}
            >
              <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
                <Grid>
                  <Grid.Col lg={1} className="avt-section">
                    <Avatar
                      src=""
                      radius="xl"
                      size="md"
                      sx={{ display: 'block', margin: 'auto' }}
                    />
                  </Grid.Col>
                  <Grid.Col lg={11} className="profile-section">
                    <Grid>
                      <Grid.Col lg={3} pl={40}>
                        <Text component="h6" className="label">
                          Patient Name
                        </Text>
                        {sessionDetails?.patientnName?.toLowerCase() !== 'unknown' ? (
                          <Anchor href={`/Patient/${sessionDetails?.patientId}/details`} target="_blank">
                            {sessionDetails.patientnName}
                          </Anchor>
                        ) : (
                          <Text>{sessionDetails?.patientnName}</Text>
                        )}
                      </Grid.Col>
                      <Grid.Col lg={3} pl={40}>
                        <Text component="h6" className="label">
                          Appointment Date & Time
                        </Text>
                        <Text component="span">
                          {sessionDetails?.appointmentDate
                            ? sessionDetails?.appointmentDate
                            : '-'}
                        </Text>
                      </Grid.Col>
                      <Grid.Col lg={3} pl={40}>
                        <Text component="h6" className="label">
                          No. of Notes Generated
                        </Text>
                        <Text component="span">{summary?.length || '-'}</Text>
                      </Grid.Col>
                      {medplum.isSuperAdmin() && (
                        <Grid.Col lg={3} pl={40}>
                          <Text component="h6" className="label">
                            Practitioner Name
                          </Text>
                          <Anchor href={`/Practitioner/${sessionDetails?.practitionerId}/details`} target="_blank">
                            {sessionDetails?.practitionerName || '-'}
                          </Anchor>
                        </Grid.Col>
                      )}
                    </Grid>
                  </Grid.Col>
                </Grid>
              </Card.Section>
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
      <Box px="lg" pb="sm" mt={20}>
        <Grid>
          {isProgressBarVisible && (
            <Grid.Col md={2} mb={12}>
              <Text>Generating Note</Text>
              <Progress
                size="xl"
                label={`${progressPercentage}%`}
                value={progressPercentage}
                color={progressPercentage === 100 ? 'green' : 'blue'}
              />
            </Grid.Col>
          )}
        </Grid>

        <Grid>
          <Grid.Col span={12} md={8} mt={8}>
            {sessionDetails?.patientnName && (
              <Tabs defaultValue={sessionDetails?.patientnName === "unknown" ? "transcript" : "notedetails"}>
                <Center w="100%" style={{ justifyContent: 'space-between' }}>
                  <Tabs.List sx={{ borderBottom: 'none' }}>
                    {sessionDetails?.patientnName !== "unknown" && (
                      <Tabs.Tab
                        className='customTooltip'
                        value="notedetails"
                        disabled={!isNotesTabEnabled || isCheckLoader}
                        fw={600}
                        onClick={() => handleClickTabs('notedetails')}>
                        Notes
                        <Tooltip
                          label="You can edit notes without changing the transcript. Updates are auto-saved when switching screens. To regenerate notes, update the transcript first."
                          withArrow
                          position="right"
                        >
                          <span style={{ marginLeft: '5px', cursor: 'pointer' }}>
                            <IconInfoCircle color='#444444d9' size={16} />
                          </span>
                        </Tooltip>
                      </Tabs.Tab>
                    )}
                    <Tabs.Tab className='customTooltip' value="transcript" fw={600} onClick={() => handleClickTabs('transcript')}>Transcript
                      <Tooltip
                        label="You can edit the transcript and save it or update it to regenerate the note. Transcript updates are auto-saved when switching screens."
                        withArrow
                        position="right"
                      >
                        <span style={{ marginLeft: '5px', cursor: 'pointer' }}>
                          <IconInfoCircle color='#444444d9' size={16} />
                        </span>
                      </Tooltip>
                    </Tabs.Tab>
                  </Tabs.List>
                </Center>
                {isCheckSession && (
                  <Tabs.Panel value="transcript">
                    <div ref={(ref) => ref && setJsxRendered(true)}>
                      <Grid>
                        <Grid.Col span={12} md={12} mt={20}>
                          <Card
                            shadow="sm"
                            sx={{ overflow: 'auto', border: '1px solid #ebebeb', borderRadius: '10px' }}
                          >
                            <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
                              <Grid mt={10} mb={6} sx={{ display: 'block' }}>
                                <Text className="f-dark" fw={700} ml={9} mb={6} sx={{ fontSize: '18px' }}>
                                  Transcript
                                </Text>
                                <Box className="soap-field" ml={10} style={{ minHeight: '400px' }}>
                                  <div
                                    className="text-area"
                                    id="transcripts_data"
                                    contentEditable={!isloading}
                                    onInput={handleDivInput}
                                  ></div>
                                </Box>
                              </Grid>
                            </Card.Section>
                          </Card>
                        </Grid.Col>
                      </Grid>
                      <Center w="100%" mt="lg" className='transcriptBtn'>
                        {sessionDetails?.patientnName === 'unknown' && (
                          <Tooltip
                            label="End unassigned session and assign them to a patient."
                            withArrow
                            position="top"
                            sx={{ fontSize: '12px', maxWidth: '170px', whiteSpace: 'normal', overflowWrap: 'break-word', textAlign: 'left' }}
                          >
                            <Button
                              className="btn-primary"
                              mr="lg"
                              onClick={handleEndSessionClick}
                              disabled={disableButtons || isStartRecordeing}
                            >
                              End Unknown Session
                            </Button>
                          </Tooltip>
                        )}
                        {!isStartRecordeing ? (
                          <Button
                            className="btn-primary"
                            mr="lg"
                            onClick={() => {
                              startStopTranscriptionTimer(true);
                              startTranscription();
                            }}
                            disabled={disableButtons}
                          >
                            Record More <IconMicrophone size={15} style={{ marginLeft: '5px' }} />
                          </Button>
                        ) : (
                          <Group>
                            <Button
                              className={`btn-danger ${audioState === recordTypes.STARTED && 'active-recording'}`}
                              h={20}
                              w={20}
                              p="2px"
                              style={{ background: 'red', borderRadius: '50%' }}
                            >
                              <IconMicrophone size={14} color="white" />
                            </Button>
                            <Button
                              className="btn-primary"
                              mr="lg"
                              onClick={() => {
                                startStopTranscriptionTimer(false);
                                stopTranscription();
                              }}
                            >
                              Stop
                            </Button>
                          </Group>
                        )}
                        <Button
                          className="btn-primary"
                          mr="lg"
                          onClick={saveTranscript}
                          disabled={!isSaveTranscripts || disableButtons || isCheckLoaderTranscript}
                        >
                          {isCheckLoaderTranscript ? (
                            <div className='save-transcript-btn'>
                              <Loader size={20} />
                              <span>Saving Transcript...</span>
                            </div>
                          ) : (
                            'Save Transcript'
                          )}
                        </Button>

                        {sessionDetails?.patientnName === 'unknown' && (
                          <Button
                            className="btn-danger"
                            mr="lg"
                            onClick={() => setUnknownSessionConfirm(true)}
                            disabled={disableButtons || isStartRecordeing}
                          >
                            Delete Session
                          </Button>
                        )}

                        {sessionDetails?.patientnName !== 'unknown' && (
                          <Tooltip
                            label="Save the transcript and regenerate all notes using the updated version of transcript."
                            withArrow
                            position="top"
                          >
                            <Button
                              className="btn-primary"
                              mr="lg"
                              onClick={updateGeneratedTranscript}
                              disabled={!isUpdateTranscripts || isSaveUpateClick}
                            >
                              {isCheckLoader ? (
                                <>
                                  Updating Notes...
                                  <Loader size={20} ml={10} />
                                </>
                              ) : (
                                'Save & Update Notes'
                              )}
                            </Button>
                          </Tooltip>
                        )}
                      </Center>
                    </div>
                  </Tabs.Panel>
                )}
                <Tabs.Panel value="notedetails">
                  <Grid className="template-tabs">
                    <Grid.Col span={12} md={9} mt={8}>
                      {isTabsLoad && (
                        <Tabs
                          defaultValue={selectedButton}
                          style={{ margin: '15px 0px 0px', padding: 'calc(1rem / 2)' }}
                        >
                          <Tabs.List sx={{ borderBottom: 'none' }}>
                            {summary?.map((data: any) => (
                              <Tooltip label={data.compositionName} position="top" key={data.compositionId}>
                                <Tabs.Tab
                                  value={data.compositionId}
                                  onClick={() => handleTemplateTabClick(data)}
                                  disabled={isTemplateListBtnDisabled || isProgressBarVisible || isloading}
                                  sx={{ width: `${summary.length > 6 ? '100px' : 'auto'}`, overflow: 'hidden', textOverflow: 'clip', display: 'block !important', whiteSpace: 'nowrap', borderBottom: '0.125rem solid #dee2e6' }}
                                >
                                  {data.compositionName}
                                </Tabs.Tab>
                              </Tooltip>
                            ))}
                          </Tabs.List>
                        </Tabs>
                      )}
                    </Grid.Col>
                    {/* note length dropdown component */}
                    {renderNoteLengthSelect()}

                    <Grid.Col span={12} md={12}>
                      {/* card component */}
                      <Card className='summary-text-area' shadow="sm">
                        <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
                          {(segmentedNoteTitle === "" || isloading || isProgressBarVisible) ? (
                            <Group align='center' className='loader-div'> <Loader size="34" /></Group>
                          ) : ((segmentedNoteTitle && segmentedNoteTitle.includes("SOAP")) || (isSegmentedNote && selectedButton)) ? (
                            <>
                              {/* Display SOAP sections (Subject, Objective, Assessment, Plan) */}
                              <RichTextEditorContainer
                                label="Subjective"
                                value={subject}
                                editor={subjectEditor}
                                isCheckActionBtn={isCheckActionBtn}
                                section="Subjective"
                                handleCopy={handleCopy}
                                handleDownload={(label) => handleDownload(label, subject)}
                                copiedSection={copiedSection}
                                isCopyLoading={isCopyLoading}
                                downloadingSection={downloadingSection}
                                isDownloadLoading={isDownloadLoading}
                                isEmailLoading={isProgressBarVisible}
                                handleEmailClick={(label) => handleEmailClick(label, subject)}
                              />
                              <RichTextEditorContainer
                                label="Objective"
                                value={objective}
                                editor={objectiveEditor}
                                isCheckActionBtn={isCheckActionBtn}
                                section="Objective"
                                handleCopy={handleCopy}
                                handleDownload={(label) => handleDownload(label, objective)}
                                copiedSection={copiedSection}
                                isCopyLoading={isCopyLoading}
                                downloadingSection={downloadingSection}
                                isDownloadLoading={isDownloadLoading}
                                isEmailLoading={isProgressBarVisible}
                                handleEmailClick={(label) => handleEmailClick(label, objective)}
                              />
                              <RichTextEditorContainer
                                label="Assessment"
                                value={assessment}
                                editor={assessmentEditor}
                                isCheckActionBtn={isCheckActionBtn}
                                section="Assessment"
                                handleCopy={handleCopy}
                                handleDownload={(label) => handleDownload(label, assessment)}
                                copiedSection={copiedSection}
                                isCopyLoading={isCopyLoading}
                                downloadingSection={downloadingSection}
                                isDownloadLoading={isDownloadLoading}
                                isEmailLoading={isProgressBarVisible}
                                handleEmailClick={(label) => handleEmailClick(label, assessment)}
                              />
                              <RichTextEditorContainer
                                label="Plan"
                                value={plan}
                                editor={planEditor}
                                isCheckActionBtn={isCheckActionBtn}
                                section="Plan"
                                handleCopy={handleCopy}
                                handleDownload={(label) => handleDownload(label, plan)}
                                copiedSection={copiedSection}
                                isCopyLoading={isCopyLoading}
                                downloadingSection={downloadingSection}
                                isDownloadLoading={isDownloadLoading}
                                isEmailLoading={isProgressBarVisible}
                                handleEmailClick={(label) => handleEmailClick(label, plan)}
                              />
                            </>
                          ) : (
                            // Display complete note Textarea for non segmented notes
                            <div className='rich-text-editor'>
                              <CustomTextarea
                                label=""
                                initialValue={parseHTMLToPlainText(summaryNote)}
                                ref={customTextareaRef}
                                isCheckActionBtn={isCheckActionBtn}
                                copyDetails={copyDetails}
                                isloading={isloading}
                                isCopied={isCopied}
                                isDownloading={isDownloading}
                                downloadSummary={downloadSummary}
                                setIsConsentBox={setIsConsentBox}
                                setConsentBoxEvent={setConsentBoxEvent}
                                isEmailLoading={isProgressBarVisible}
                              />
                            </div>
                          )}

                          <Center w="100%" mt="lg" style={{ justifyContent: 'flex-end' }}>
                            {/* entire note action buttons start*/}
                            {(segmentedNoteTitle && segmentedNoteTitle.includes("SOAP")) && (
                              <Group mr={20} className='note-action-btns'>
                                <Tooltip label="Copy the entire note" withArrow>
                                  <Button
                                    size="xs"
                                    className={`btn-primary ${copiedSection === 'all' && 'btn-success'}`}
                                    disabled={isloading}
                                    onClick={() => handleSectCopy({ Subjective: subject, Objective: objective, Assessment: assessment, Plan: plan }, 'all')}
                                  >
                                    {copiedSection === 'all' ? 'Copied' : <IconCopy size={18} />}
                                  </Button>
                                </Tooltip>
                                <Tooltip label="Download the entire note" withArrow>
                                  <Button
                                    size="xs"
                                    disabled={isloading}
                                    className={`btn-primary ${downloadingSection === 'SOAP Download' ? 'btn-success' : ''}`}
                                    onClick={handleDownloadEntireNote}
                                  >
                                    {downloadingSection === 'SOAP Download' ? 'Downloading...' : <IconDownload size={18} />}
                                  </Button>
                                </Tooltip>
                                <Tooltip label="Send the entire note via email" withArrow>
                                  <Button
                                    size="xs"
                                    className="btn-primary"
                                    disabled={isloading}
                                    onClick={() => handleSendEntireNoteEmail()}
                                  >
                                    <IconMail size={18} />
                                  </Button>
                                </Tooltip>
                              </Group>
                            )}
                            {/* entire note action buttons end*/}

                            {/* TODO: Hide the "Regenerate Note" button temporarily.*/}
                            {/* <Tooltip label="Regenerate the selected note with updated content." withArrow position="left">
                              <Button
                                mr="lg"
                                onClick={handleRegenerateClick}
                                className="feedbackBtn"
                                disabled={isloading || segmentedNoteTitle === "" || isProgressBarVisible}
                                sx={{ backgroundColor: '#8dc744' }}
                              >
                                {isloading ? <Loader size={20} color='white' /> : 'Regenerate Note'}

                              </Button>
                            </Tooltip> */}
                            <Tooltip label="Please rate the quality of your note." withArrow position="left">
                              <Button
                                mr="lg"
                                onClick={handleFeedbackClick}
                                className="feedbackBtn"
                                disabled={isloading || segmentedNoteTitle === "" || isProgressBarVisible}
                                sx={{ backgroundColor: '#8dc744' }}
                              >
                                {isloading ? <Loader size={20} color='white' /> : 'Feedback'}

                              </Button>
                            </Tooltip>
                            <Button
                              className="btn-primary"
                              mr="lg"
                              disabled={!isSummaryUpdated || isloading || isProgressBarVisible}
                              onClick={handleSaveNotesClick}
                            >
                              {isloading ? <Loader size={20} color='white' /> : 'Save Notes'}
                            </Button>
                          </Center>
                        </Card.Section>
                      </Card>
                    </Grid.Col>
                  </Grid>
                </Tabs.Panel>
              </Tabs>
            )}
          </Grid.Col>
          <Grid.Col span={12} md={4} mt={8}>
            {summary?.length > 0 && (
              <div style={{ display: isMobile && selectedTab === "transcript" ? 'none' : 'block' }}>
                <TemplateList selectedTemplateNote={getSummary} summary={summary} />
              </div>
            )}
          </Grid.Col>
        </Grid>
      </Box>

      {isAddPatient && (
        <PatientStepper
          isOpen={isAddPatient}
          onClose={() => onCancel()}
          onConfirm={endUnknownSession}
          checkLoader={isCheckNextBtnLoader}
        />
      )}

      <UnknownSessionConfirmBox
        isOpen={unknownSessionConfirm}
        onClose={() => setUnknownSessionConfirm(false)}
        onConfirm={handleConfirmDelete}
        patient={''}
        confirm="Delete"
        cancel="Cancel"
        message={`Are you sure you want to delete this unknown session?`}
        additionalText=""
        isLoader={isDeletingLoader}
      />

      {isConfirmOpen && (
        <ConfirmBox
          isOpen={isConfirmOpen}
          onClose={() => setIsConfirmOpen(false)}
          onConfirm={() => onConfirmExit()}
          content={
            'Please note that confirming this action will completely discard the unsaved changes. Would you like to proceed?'
          }
        />
      )}

      {isGeneratedTranscript && (
        <ConfirmBox
          isOpen={isGeneratedTranscript}
          onClose={() => setIsGeneratedTranscript(false)}
          onConfirm={() => updateGeneratedTranscript()}
          content={`Following notes will be updated ${summary
            ?.map((entry: { compositionName: any }) => entry.compositionName)
            .join(', ')}`}
        />
      )}

      {isConsentBox && (
        <ConfirmBox
          isOpen={isConsentBox}
          onClose={() => setIsConsentBox(false)}
          onConfirm={() => {
            const [event, label, ...contentParts] = consentBoxEvent.split('|');
            const content = contentParts.join('|');
            if (event === 'email') {
              setOpenEmailBox(true);
              setIsConsentBox(false);
              setEmailBoxSoapSection(label);
              setEmailBoxContent(content);
            } else {
              downloadSummary();
              setIsConsentBox(false);
            }
          }}
          content={`Please note that you would need patient's consent to share Patient's PHI data externally.`}
        />
      )}

      {openEmailBox && (
        <EmailBox isOpen={openEmailBox} onClose={() => setOpenEmailBox(false)} documentReferenceId={noteId} defaultEmail={defaultPatientEmail} soapSection={emailBoxSoapSection} summaryNote={summaryNote} content={emailBoxContent} />
      )}

      {popupOpened && (
        <FeedbackRatingPopup
          opened={popupOpened}
          onClose={handlePopupClose}
          onSubmit={handlePopupSubmit}
          noteId={noteId}
        />
      )}

      {/* note length confirmation modal */}
      <ConfirmationModal
        isOpen={isNoteLengthSelected}
        onClose={handleCloseModal}
        onConfirm={() => handleNoteRegenerationBasedOnLength()}
        content={`Do you want to regenerate the ${segmentedNoteTitle} note with ${noteLengthType} information? You will lose any changes made to the  initial note.`}
        textStyle={{ fontSize: '17px' }}
        confirmButtonText="Yes"
        cancelButtonText="No"
        isLoading={noteRegenerateLoader}
      />

    </>
  );
};

