import { Anchor, Button, Center, Checkbox, Divider, Group, PasswordInput, Stack, TextInput } from '@mantine/core';
import {
  BaseLoginRequest,
  GoogleCredentialResponse,
  GoogleLoginRequest,
  LoginAuthenticationResponse,
  normalizeOperationOutcome,
} from '@medplum/core';
import { OperationOutcome } from '@medplum/fhirtypes';
import React, { useCallback, useState } from 'react';
import { Form } from '../Form/Form';
import { GoogleButton } from '../GoogleButton/GoogleButton';
import { getGoogleClientId } from '../GoogleButton/GoogleButton.utils';
import { useMedplum } from '@medplum/react-hooks';
import { OperationOutcomeAlert } from '../OperationOutcomeAlert/OperationOutcomeAlert';
import { getErrorsForInput, getIssuesForExpression } from '../utils/outcomes';
import { useLocation } from 'react-router-dom';

export interface AuthenticationFormProps extends BaseLoginRequest {
  readonly disableEmailAuth?: boolean;
  readonly disableGoogleAuth?: boolean;
  readonly onForgotPassword?: () => void;
  readonly onRegister?: () => void;
  readonly handleAuthResponse: (response: LoginAuthenticationResponse) => void;
  readonly children?: React.ReactNode;
}

export function AuthenticationForm(props: AuthenticationFormProps): JSX.Element {
  const [email, setEmail] = useState<string>();

  if (!email) {
    return <EmailForm setEmail={setEmail} {...props} />;
  } else {
    return <PasswordForm email={email} {...props} />;
  }
}

export interface EmailFormProps extends BaseLoginRequest {
  readonly disableEmailAuth?: boolean;
  readonly disableGoogleAuth?: boolean;
  readonly onRegister?: () => void;
  readonly handleAuthResponse: (response: LoginAuthenticationResponse) => void;
  readonly setEmail: (email: string) => void;
  readonly children?: React.ReactNode;
  readonly onForgotPassword?: () => void;
}

export function EmailForm(props: EmailFormProps): JSX.Element {
  const location = useLocation();
  const { username, password } = location.state || {};
  const { handleAuthResponse, children, disableEmailAuth, onForgotPassword, ...baseLoginRequest } = props;
  const medplum = useMedplum();
  const googleClientId = !props.disableGoogleAuth && getGoogleClientId(props.googleClientId);
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const issues = getIssuesForExpression(outcome, undefined);
  const [loginBtnLoading, setLoginBtnLoading] = useState(false);

  const isExternalAuth = useCallback(
    async (authMethod: any): Promise<boolean> => {
      if (!authMethod.authorizeUrl) {
        return false;
      }

      const state = JSON.stringify({
        ...(await medplum.ensureCodeChallenge(baseLoginRequest)),
        domain: authMethod.domain,
      });
      const url = new URL(authMethod.authorizeUrl);
      url.searchParams.set('state', state);
      window.location.assign(url.toString());
      return true;
    },
    [medplum, baseLoginRequest]
  );

  /*
  const handleSubmitEmail = useCallback(
    async (formData: Record<string, string>) => {
      const authMethod = await medplum.post('auth/method', { email: formData.email });
      if (!(await isExternalAuth(authMethod))) {
        setEmail(formData.email);
      }
    },
    [medplum, isExternalAuth, setEmail]
  );
  */

  const handleSubmit = useCallback(
    async (formData: Record<string, string>) => {
      setLoginBtnLoading(true);
      let retries = 2;

      const attemptLogin = async () => {
        try {
          const response = await medplum.startLogin({
            ...baseLoginRequest,
            password: formData.password,
            remember: formData.remember === 'on',
            email: formData.email,
          });
          handleAuthResponse(response);
        } catch (err: any) {
          if (err.message.includes("Failed to fetch") && retries > 0) {
            retries--;
            // Clear cache
            medplum.invalidateAll();
            await attemptLogin();

          } else {
            setOutcome(normalizeOperationOutcome(err));
            setLoginBtnLoading(false);
          }
        }
      };

      await attemptLogin();
    },
    [medplum, baseLoginRequest, handleAuthResponse]
  );

  const handleGoogleCredential = useCallback(
    async (response: GoogleCredentialResponse) => {
      const authResponse = await medplum.startGoogleLogin({
        ...baseLoginRequest,
        googleCredential: response.credential,
      } as GoogleLoginRequest);
      if (!(await isExternalAuth(authResponse))) {
        handleAuthResponse(authResponse);
      }
    },
    [medplum, baseLoginRequest, isExternalAuth, handleAuthResponse]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Center sx={{ flexDirection: 'column' }}>{children}</Center>
      {googleClientId && (
        <>
          <Group position="center" p="xl" style={{ height: 70 }}>
            <GoogleButton googleClientId={googleClientId} handleGoogleCredential={handleGoogleCredential} />
          </Group>
          {!disableEmailAuth && <Divider label="or" labelPosition="center" my="lg" />}
        </>
      )}
      {!disableEmailAuth && (
        <div>
          <OperationOutcomeAlert issues={issues} />
          <TextInput
            name="email"
            type="email"
            label="Email"
            required={true}
            autoFocus={true}
            className="login-input"
            style={{ marginBottom: '15px' }}
            defaultValue={username}
          />
          <Stack spacing="xl">
            <PasswordInput
              name="password"
              label="Password"
              autoComplete="off"
              required={true}
              error={getErrorsForInput(outcome, 'password')}
              className="password-input"
              defaultValue={password}
            />
          </Stack>
        </div>
      )}
      <Group mt="xl" spacing={0} noWrap style={{ justifyContent: 'space-between' }}>

        <Checkbox id="remember" name="remember" label="Keep me logged in" size="xs" sx={{ lineHeight: 1 }} />

        {onForgotPassword && (
          <Anchor className="forgot-password-text" component="button" type="button" color="dimmed" onClick={onForgotPassword} size="xs" style={{ textDecoration: 'underline' }}>
            Forgot Password?
          </Anchor>
        )}
      </Group>
      <Group position="apart" spacing={0} noWrap sx={{ display: 'block' }}>
        {!disableEmailAuth && (
          <>
            <Button
              className="login-button"
              type="submit"
              disabled={loginBtnLoading}
              loading={loginBtnLoading}
              style={{
                opacity: loginBtnLoading ? 0.9 : 1,
                borderRadius: loginBtnLoading ? '4px' : '8px',
                color: 'white',
              }}
            >
              {loginBtnLoading ? 'Please wait...' : 'Login'}
            </Button>
            <div className="loginDiv">
              Don't have an account yet? <Anchor href="/sign-up" className="registerLink" color='white' sx={{ textDecoration: 'underline' }}>Register</Anchor>
            </div>
          </>
        )}
      </Group>
    </Form>
  );
}

export interface PasswordFormProps extends BaseLoginRequest {
  readonly email: string;
  readonly onForgotPassword?: () => void;
  readonly handleAuthResponse: (response: LoginAuthenticationResponse) => void;
  readonly children?: React.ReactNode;
}

export function PasswordForm(props: PasswordFormProps): JSX.Element {
  const { handleAuthResponse, children, ...baseLoginRequest } = props;
  const medplum = useMedplum();
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const issues = getIssuesForExpression(outcome, undefined);

  const handleSubmit = useCallback(
    (formData: Record<string, string>) => {
      medplum
        .startLogin({
          ...baseLoginRequest,
          password: formData.password,
          remember: formData.remember === 'on',
        })
        .then(handleAuthResponse)
        .catch((err) => setOutcome(normalizeOperationOutcome(err)));
    },
    [medplum, baseLoginRequest, handleAuthResponse]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Center sx={{ flexDirection: 'column' }}>{children}</Center>
      <OperationOutcomeAlert issues={issues} />
      <Stack spacing="xl">
        <PasswordInput
          name="password"
          label="Password"
          autoComplete="off"
          placeholder="********"
          required={true}
          autoFocus={true}
          error={getErrorsForInput(outcome, 'password')}
          className="password-input"
        />
      </Stack>
      {/* <Group mt="xl" spacing={0} noWrap style={{ justifyContent: "flex-end"}}>
        {onForgotPassword && (
          <Anchor className="forgot-password-text" component="button" type="button" color="dimmed" onClick={onForgotPassword} size="xs">
            Forgot password
          </Anchor>
        )}
      </Group> */}
      <Group display="block" mt="xl" position="apart" spacing={0} noWrap>
        {/* <Checkbox id="remember" name="remember" label="Remember me" size="xs" sx={{ lineHeight: 1 }} /> */}
        <Button className="login-button" type="submit">Sign in</Button>
      </Group>
    </Form>
  );
}
