import React from 'react'
import { Paper, Grid, Text, Button, Table, Box, Loader, Group, Tabs, Select } from '@mantine/core';
import { useMedplum, AsyncAutocomplete } from '@medplum/react';
import { IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
import { buildGraphQLQuery, getResourcesFromResponse, HeaderSearchTypes, SearchGraphQLResponse, toKey, toOption } from '../../../react/src/AppShell/HeaderSearchInput';
import { useCallback, useEffect, useState, useRef } from 'react';
import { getPractitionerActivityMetrics, getLoginAttemptsMetrics, getRegLoginsData } from '../utils/util';
import Overview from './Overview';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

interface PractitionerActivityData {
  practitioner: string;
  activity: string;
  description: string;
  date: string;
}

interface LoginDetail {
  authTime: string;
  browserUsed: string;
  address: string;
}

interface LoginAttemptData {
  id: string;
  practitioner: string;
  email: string;
  recentLogin: string;
  loginDetails: LoginDetail[];
}

interface RegLoginsData {
  date: string;
  loginCount: number;
  registrations: number;
}

interface ErrorStatusFilterProps {
  value: string;
  onChange: (value: string | null) => void;
  disabled: boolean;
}

const practitionerActivityTableFields = ['practitioner', 'recentActivity', 'description', 'date'];
const loginAttemptTableFields = ['practitioner', 'email', 'browserUsed', 'recentLogin'];

const PractitionerActivityTableRows = ({ data }: { data: PractitionerActivityData[] }) => (
  <tbody className='payment-status-table-body'>
    {data.length > 0 ? data.map((resource, index) => (
      <tr key={index} style={{ border: '1px solid #ebeae9' }}>
        {practitionerActivityTableFields.map((field, i) => (
          <td key={`cell-${index}-${i}`}
          >
            {renderPractitionerActivityValue(resource, field)}</td>
        ))}
      </tr>
    )) : <tr><td colSpan={practitionerActivityTableFields.length + 1}>No data available</td></tr>}
  </tbody>
);

const renderPractitionerActivityValue = (data: PractitionerActivityData, field: string) => {
  if (!data) return '-';

  const date = new Date(data.date);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  switch (field) {
    case 'practitioner':
      return data.practitioner || '-';
    case 'recentActivity':
      return data.activity || '-';
    case 'description':
      return data.description || '-';
    case 'date':
      return data.date ? (
        <>
          {formattedDate} &nbsp;
          {formattedTime}
        </>
      ) : '-';
    default:
      return '-';
  }
};

const renderLoginAttemptValue = (data: LoginAttemptData, field: string) => {
  if (!data) return '-';
  switch (field) {
    case 'practitioner':
      return data.practitioner || '-';
    case 'email':
      return data.email || '-';
    case 'browserUsed':
      return data.loginDetails.length > 0 ? data.loginDetails[0].browserUsed || '-' : '-';
    case 'recentLogin':
      return data.recentLogin || '-';
    default:
      return '-';
  }
};

const PractitionerActivityTable = () => (
  <thead>
    <tr>
      {practitionerActivityTableFields.map((field, index) => (
        <th key={index}
          style={(field === 'date' || field === 'practitioner') ? { width: '180px' } : field === 'recentActivity' ? { width: '210px' } : {}}
        >
          <Group position="apart" noWrap>
            <Text weight={500} size="sm">
              {field === 'practitioner' ? 'Practitioner' :
                field === 'recentActivity' ? 'Recent Activity' :
                  field === 'description' ? 'Description' :
                    field === 'date' ? 'Date & Time' :
                      ''}
            </Text>
          </Group>
        </th>
      ))}
      <th></th>
    </tr>
  </thead>
);

const LoginTableHeaders = () => (
  <thead>
    <tr>
      {loginAttemptTableFields.map((field, index) => (
        <th key={index}>
          <Group position="apart" noWrap>
            <Text weight={500} size="sm">
              {field === 'practitioner' ? 'Practitioner' :
                field === 'email' ? 'Email' :
                  field === 'browserUsed' ? 'Browser' :
                    field === 'recentLogin' ? 'Last Login' :
                      ''}
            </Text>
          </Group>
        </th>
      ))}
      <th></th>
    </tr>
  </thead>
);

// Activity by Practitioners table filter options
const errorStatusOptions = [
  { value: '', label: 'All Activities' },
  { value: 'Subscription Update Error', label: 'Subscription Update Error' },
  { value: 'Stripe Checkout Error', label: 'Stripe Checkout Error' },
  { value: 'NPI Details Error', label: 'NPI Details Error' },
  { value: 'Registration Error', label: 'Registration Error' },
  { value: 'New Patient Error', label: 'New Patient Error' },
  { value: 'Complete Patient Error', label: 'Complete Patient Error' },
  { value: 'SOAP Error', label: 'SOAP Error' },
  { value: 'Update SOAP Error', label: 'Update SOAP Error' },
  { value: 'Stripe Renew Error', label: 'Stripe Renew Error' },
  { value: 'Save Feedback Error', label: 'Save Feedback Error' },
  { value: 'Update Patient Error', label: 'Update Patient Error' },
  { value: 'IOS New User Error', label: 'IOS New User Error' },
  { value: 'Download Note Error', label: 'Download Note Error' },
  { value: 'Send Email Error', label: 'Send Email Error' },
  { value: 'Stripe Webhook Error', label: 'Stripe Webhook Error' },
  { value: 'Update Note Error', label: 'Update Note Error' },
  { value: 'Update Transcript Error', label: 'Update Transcript Error' },
].sort((a, b) => a.label.localeCompare(b.label));

export const ErrorStatusFilter: React.FC<ErrorStatusFilterProps> = ({
  value,
  onChange,
  disabled
}) => (
  <Grid.Col span={12} sx={{ display: 'flex', paddingTop: '10px' }}>
    <Text size={14} fw={500} color="#344054" p={6}>
      Filter by Error
    </Text>
    <Select
      value={value}
      onChange={onChange}
      disabled={disabled}
      data={errorStatusOptions}
      placeholder="Select status"
      sx={{ width: 'auto' }}
    />
  </Grid.Col>
);
// Activity by Practitioners table filter options end

function UserActivity() {
  const medplum = useMedplum();
  const [loadingNextPractitionerActivity, setLoadingNextPractitionerActivity] = useState<boolean>(false);
  const [loadingPrevPractitionerActivity, setLoadingPrevPractitionerActivity] = useState<boolean>(false);
  const [activityTableLoading, setActivityTableLoading] = useState<boolean>(true);
  const [selectedPractitionerSearchId, setselectedPractitionerSearchId] = useState<string | null>(null);
  const [selectedLoginPractitionerSearchId, setselectedLoginPractitionerSearchId] = useState<string | null>(null);
  const [practitionerActivityData, setPractitionerActivityData] = useState<PractitionerActivityData[]>([]);
  const resourceID = medplum.getActiveLogin()?.profile?.reference;
  const parts: any = resourceID?.split('/');
  const adminId = parts?.[1];
  const skipEffect = useRef(false);
  const skipLoginEffect = useRef(false);
  const practitionerActivityPerPage = 10;
  const [practitionerActivityOffset, setPractitionerActivityOffset] = useState<number>(0);
  const [loginTableLoader, setLoginTableLoader] = useState<boolean>(true);
  const loginAttemptsPerPage = 10;
  const [loginAttemptsOffset, setLoginAttemptsOffset] = useState<number>(0);
  const [loginLoadingNext, setLoginLoadingNext] = useState<boolean>(false);
  const [loginLoadingPrev, setLoginLoadingPrev] = useState<boolean>(false);
  const [loginAttemptsData, setLoginAttemptsData] = useState<LoginAttemptData[]>([]);
  const [regLoginChartLoader, setRegLoginChartLoader] = useState<boolean>(true);
  const [regLoginsData, setRegLoginsData] = useState<RegLoginsData[]>([]);
  const [regLoginsDuration, setRegLoginsDuration] = useState<string>('week');
  const [activityTableFilter, setActivityTableFilter] = useState<string>('');

  // Add this function for practitioner loading search data
  const practitionerSearchloadData = useCallback(
    async (input: string, signal: AbortSignal): Promise<HeaderSearchTypes[]> => {
      const query = buildGraphQLQuery(input, 'practitioner', 20);
      const options = { signal };
      const response = (await medplum.graphql(query, undefined, undefined, options)) as SearchGraphQLResponse;
      return getResourcesFromResponse(response, input, 20);
    },
    [medplum]
  );

  const fetchPractitionerActivityData = useCallback(async (count: number, offset: number, adminId: string, activityTableFilter?: string, selectedPractitionerSearchId?: string) => {
    try {
      const data = await getPractitionerActivityMetrics(medplum, count, offset, adminId, activityTableFilter || '', selectedPractitionerSearchId || '');
      setPractitionerActivityData(data || []);
    } catch (error) {
      console.error('Error fetching practitioner activity data:', error);
      setPractitionerActivityData([]);
    } finally {
      setActivityTableLoading(false);
      setLoadingNextPractitionerActivity(false);
      setLoadingPrevPractitionerActivity(false);
    }
  }, [medplum]);

  const handlePractitionerActivityPageChange = (direction: 'next' | 'prev') => {
    if (direction === 'next') {
      setLoadingNextPractitionerActivity(true);
      setPractitionerActivityOffset(practitionerActivityOffset + practitionerActivityPerPage);
    } else {
      setLoadingPrevPractitionerActivity(true);
      setPractitionerActivityOffset(Math.max(practitionerActivityOffset - practitionerActivityPerPage, 0));
    }
  };

  useEffect(() => {
    if (skipEffect.current) {
      skipEffect.current = false;
      return;
    }
    setActivityTableLoading(true);
    fetchPractitionerActivityData(practitionerActivityPerPage, practitionerActivityOffset, adminId, activityTableFilter, selectedPractitionerSearchId?.toString());
  }, [fetchPractitionerActivityData, practitionerActivityOffset, adminId, activityTableFilter, selectedPractitionerSearchId]);

  const handlePractitionerSearch = useCallback(
    async (item: HeaderSearchTypes[]): Promise<void> => {
      const id = item.length > 0 ? item[0].id ?? null : null;
      setselectedPractitionerSearchId(id);
      setPractitionerActivityOffset(0);
      skipEffect.current = true;
      fetchPractitionerActivityData(practitionerActivityPerPage, 0, adminId, activityTableFilter, id?.toString());
    },
    [fetchPractitionerActivityData, practitionerActivityPerPage, practitionerActivityOffset, adminId, activityTableFilter, selectedPractitionerSearchId]
  );

  const fetchLoginAttemptsData = useCallback(async (count: number, offset: number, selectedLoginPractitionerSearchId?: string, searchByEmailValue?: string) => {
    setLoginTableLoader(true);
    try {
      const data = await getLoginAttemptsMetrics(medplum, count, offset, selectedLoginPractitionerSearchId || '', searchByEmailValue ? searchByEmailValue : '');
      setLoginAttemptsData(data || []);
    } catch (error) {
      console.error('Error fetching login attempts data:', error);
      setLoginAttemptsData([]);
    } finally {
      setLoginTableLoader(false);
      setLoginLoadingNext(false);
      setLoginLoadingPrev(false);
    }
  }, [medplum]);

  //fetch registration logins data
  const fetchRegLoginsData = useCallback(async () => {
    setRegLoginChartLoader(true);
    try {
      const response = await getRegLoginsData(medplum, regLoginsDuration);

      const transformedData: RegLoginsData[] = response.map((item: any) => ({
        date: item.date,
        loginCount: item.loginCount,
        registrations: item.registrations,
      }));

      // Sort the data by date in ascending order
      transformedData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      setRegLoginsData(transformedData);
    } catch (error) {
      console.error('Error fetching registration logins data:', error);
    } finally {
      setRegLoginChartLoader(false);
    }
  }, [medplum, regLoginsDuration]);

  //calculate total registrations and logins
  const getTotalCounts = () => {
    const totalRegistrations = regLoginsData.reduce((sum, item) => sum + item.registrations, 0);
    const totalLogins = regLoginsData.reduce((sum, item) => sum + item.loginCount, 0);

    return { totalRegistrations, totalLogins };
  };
  const { totalRegistrations, totalLogins } = getTotalCounts();


  //graphQL query for practitioner search for login attempts table
  const handleLoginPractitionerSearch = useCallback(
    async (items: HeaderSearchTypes[]): Promise<void> => {
      if (!items.length) {
        fetchLoginAttemptsData(loginAttemptsPerPage, 0);
        return;
      }

      const selectedItem = items[0];
      if (!selectedItem) return;
      const { id = null, identifier = [] } = selectedItem;

      setselectedLoginPractitionerSearchId(id ?? null);
      skipLoginEffect.current = true;
      setLoginAttemptsOffset(0);

      // Explicitly cast selectedItem to a type that includes telecom
      const practitioner = selectedItem as { telecom?: { system: string; value: string }[] };
      const email = practitioner.telecom?.find(t => t.system === 'email')?.value || '';

      fetchLoginAttemptsData(
        loginAttemptsPerPage,
        0,
        identifier?.length ? id?.toString() : '',
        identifier?.length ? '' : email
      );
    },
    [fetchLoginAttemptsData, loginAttemptsPerPage, loginAttemptsOffset, selectedLoginPractitionerSearchId]
  );

  const loginPractitionerSearchloadData = useCallback(
    async (input: string, signal: AbortSignal): Promise<HeaderSearchTypes[]> => {
      const isEmail = /\S+@\S+\.\S+/.test(input);
      const query = buildGraphQLQuery(input, 'practitioner', 20, isEmail);
      const options = { signal };
      const response = (await medplum.graphql(query, undefined, undefined, options)) as SearchGraphQLResponse;
      return getResourcesFromResponse(response, input, 20);
    },
    [medplum]
  );

  const handleLoginAttemptsPageChange = (direction: 'next' | 'prev') => {
    if (direction === 'next') {
      setLoginLoadingNext(true);
      setLoginAttemptsOffset(loginAttemptsOffset + loginAttemptsPerPage);
    } else {
      setLoginLoadingPrev(true);
      setLoginAttemptsOffset(Math.max(loginAttemptsOffset - loginAttemptsPerPage, 0));
    }
  };

  useEffect(() => {
    if (skipLoginEffect.current) {
      skipLoginEffect.current = false;
      return;
    }
    fetchLoginAttemptsData(loginAttemptsPerPage, loginAttemptsOffset, selectedLoginPractitionerSearchId?.toString());
  }, [fetchLoginAttemptsData, loginAttemptsOffset, selectedLoginPractitionerSearchId]);

  useEffect(() => {
    fetchRegLoginsData();
  }, [fetchRegLoginsData, regLoginsDuration]);

  const handleRegistrationLoginsTabChange = (value: string | null) => {
    if (value !== null) {
      setRegLoginsDuration(value);
    }
  };

  //Add this to render the Registrations & Logins tabs
  const renderRegistrationLoginsTabs = () => (
    <Tabs value={regLoginsDuration} onTabChange={handleRegistrationLoginsTabChange} className='filter-duration'>
      <Tabs.List className="tabs-list">
        <Tabs.Tab value="week" mb={0} className="tab">Last 7 days</Tabs.Tab>
        <Tabs.Tab value="month" mb={0} className="tab">Last 30 days</Tabs.Tab>
        <Tabs.Tab value="12months" mb={0} className="tab">Last 12 months</Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );

  //Add this to render the Registrations & Logins chart
  const renderRegistrationLoginsChart = () => {
    if (regLoginsData.length === 0) {
      return <p>No data available</p>;
    }

    const tooltipFormatter = (value: any, name: string) => {
      const formattedName = name === 'loginCount' ? 'Login Count' : name.charAt(0).toUpperCase() + name.slice(1);
      return [value, formattedName];
    };

    const legendFormatter = (value: string) => {
      return value === 'loginCount' ? 'Login Count' : value.charAt(0).toUpperCase() + value.slice(1);
    };

    return (
      <LineChart
        width={1000}
        height={400}
        data={regLoginsData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid opacity={0.3} stroke="#e0e0e0" vertical={false} />
        <XAxis dataKey="date" />
        <YAxis label={{ value: 'Count', angle: -90, position: 'insideLeft' }} />
        <Tooltip formatter={tooltipFormatter} />
        <Legend formatter={legendFormatter} />
        <Line type="monotone" dataKey="loginCount" stroke="#3276C9" strokeWidth={2} activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="registrations" stroke="#31C9C0" strokeWidth={2} />
      </LineChart>
    );
  };

  const handleActivityFilterChange = (value: string | null) => {
    setActivityTableFilter(value || '');
    setPractitionerActivityOffset(0);
  }

  const renderPractitionerLoginHistorySearch = () => (
    <div className='login-history-search'>
      <AsyncAutocomplete
        size="sm"
        radius="md"
        icon={<IconSearch size={20} />}
        placeholder={'Search Practitioner by name or email'}
        disabled={loginTableLoader}
        toKey={toKey}
        toOption={toOption}
        onChange={handleLoginPractitionerSearch}
        loadOptions={loginPractitionerSearchloadData}
        maxSelectedValues={0}
        clearSearchOnChange
        clearable={true}
        width={250}
        sx={{
          input: {
            width: '400px',
            paddingTop: '6px',
            marginTop: '-3px',
            transition: 'none',
            '&:focus-within': {
              width: '400px',
            },
          },
        }}
      />
    </div>
  );

  //Practitioner Login History
  const LoginTableRows = ({ data }: { data: LoginAttemptData[] }) => {
    const [expandedRow, setExpandedRow] = useState<number | null>(null);
    const [expandedRowData, setExpandedRowData] = useState<{ [key: number]: LoginAttemptData }>({});

    const handleRowClick = (index: number, resource: LoginAttemptData) => {
      if (expandedRow === index) {
        setExpandedRow(null);
      } else {
        setExpandedRow(index);
        setExpandedRowData((prev) => ({ ...prev, [index]: resource }));
      }
    };
    return (
      <tbody className='login-attempts-table-body'>
        {data.length > 0 ? data.map((resource, index) => (
          <React.Fragment key={index}>
            <tr key={`row-${index}`} style={{ border: '1px solid #ebeae9' }}
              onClick={() => handleRowClick(index, resource)}
              className={expandedRow === index ? 'expanded-row' : ''}
            >
              {loginAttemptTableFields.map((field, i) => (
                <td
                  key={`cell-${index}-${i}`}
                >
                  {renderLoginAttemptValue(resource, field)}
                </td>
              ))}
              <td key={`expand-icon-${index}`}>
                {resource?.loginDetails?.length > 0 ? (
                  expandedRow === index ? <IconChevronUp height='20' /> : <IconChevronDown height='20' />
                ) : (
                  <div className="h-5 w-5" />
                )}
              </td>
            </tr>
            {expandedRow === index && expandedRowData[index] && (
              <ExpandedRowContent
                key={`expanded-${index}`}
                resource={expandedRowData[index]}
                index={index}
                setExpandedRowData={setExpandedRowData}
              />
            )}
          </React.Fragment>
        )) : <tr><td colSpan={loginAttemptTableFields.length + 1}>No data available</td></tr>}
      </tbody>
    );
  };
  //Practitioner Login History end

  //Practitioner Login History expanded row
  const fetchExpandedLoginTableData = async (count: number, offset: number, practitionerId?: string, emailId?: string) => {
    try {
      const data = await getLoginAttemptsMetrics(medplum, count, offset, practitionerId, emailId);
      return data || [];
    } catch (error) {
      console.error('Error fetching login attempts data:', error);
      return [];
    }
  };

  const ExpandedRowContent = ({
    resource,
    index,
    setExpandedRowData,
  }: {
    resource: LoginAttemptData;
    index: number;
    setExpandedRowData: React.Dispatch<React.SetStateAction<{ [key: number]: LoginAttemptData }>>;
  }) => {
    const [expandedLoginAttemptsOffset, setExpandedLoginAttemptsOffset] = useState(0);
    const [hasMoreExpandedTableData, setHasMoreExpandedTableData] = useState(true);

    const handleExpandedLoginPageChange = async (direction: 'next' | 'prev') => {
      const newOffset = direction === 'next'
        ? expandedLoginAttemptsOffset + loginAttemptsPerPage
        : Math.max(expandedLoginAttemptsOffset - loginAttemptsPerPage, 0);

      setExpandedLoginAttemptsOffset(newOffset);
      const practitionerEmail = resource.id === '' ? resource.practitioner : '';
      const practitionerId = resource.id ? resource.id : '';
      // Fetch new data for the expanded row
      const updatedData = await fetchExpandedLoginTableData(loginAttemptsPerPage, newOffset, practitionerId, practitionerEmail) || [];

      const loginDetails =
        newOffset === 0 && updatedData.length === 1 && updatedData[0].loginDetails?.length
          ? updatedData[0].loginDetails
          : updatedData;

      // Update only the expanded row data
      setExpandedRowData((prev) => ({
        ...prev,
        [index]: {
          ...prev[index],
          loginDetails,
        },
      }));

      setHasMoreExpandedTableData(loginDetails.length === loginAttemptsPerPage);
    };

    return (
      <tr>
        <td colSpan={loginAttemptTableFields.length + 1} style={{ padding: '5px 1rem 1rem' }}>
          <Table className="ex-col-div">
            <thead>
              <tr>
                <th>IP Address</th>
                <th>Date & Time</th>
                <th>Browser</th>
              </tr>
            </thead>
            <tbody>
              {resource?.loginDetails?.length ? (
                resource.loginDetails.map((detail, i) => (
                  <tr key={i}>
                    <td>{detail.address}</td>
                    <td>{detail.authTime}</td>
                    <td>{detail.browserUsed}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} style={{ textAlign: 'center' }}>No login details available</td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={3} style={{ textAlign: 'center', paddingTop: '10px' }}>
                  <Button
                    onClick={() => handleExpandedLoginPageChange('prev')}
                    className="pagination-btn"
                    disabled={expandedLoginAttemptsOffset === 0}
                    mr="sm"
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={() => handleExpandedLoginPageChange('next')}
                    className="pagination-btn"
                    disabled={!hasMoreExpandedTableData || resource.loginDetails.length < loginAttemptsPerPage}
                  >
                    Next
                  </Button>
                </td>
              </tr>
            </tfoot>
          </Table>
        </td>
      </tr>
    );
  };
  //Practitioner Login History expanded row end

  return (
    <Box>
      {/* Overview section */}
      <Overview />

      {/* Registrations & Logins section */}
      <Paper m="lg" p="xs" className='paper-box'>
        <Grid className='grid-layout'>
          <Grid.Col span={6}>
            <Text align="left" size={18} fw={600} ml={10} pb={10}>Registrations & Logins  </Text>
          </Grid.Col>
          <Grid.Col span={6} sx={{ textAlign: 'end' }}>
            <Button className='download-btn'>
              <img src="../../../../img/dashboard/download.svg" alt="Download" />
              <Text pl={5} size={14} color='#344054' fw={600}>Download Report</Text>
            </Button>
          </Grid.Col>
        </Grid>
        <Grid className='grid-style'>
          <Grid.Col span={4} fw={500}>
            <Group>
              <Text className='total-regs-logins'>Total Registrations: {totalRegistrations}</Text>
              <span>|</span>
              <Text className='total-regs-logins'>Total Logins: {totalLogins}</Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={8} className='filter-div'>
            <Grid align="center" ml={20}>
              <Grid.Col span={12} pr={22}>
                {renderRegistrationLoginsTabs()}
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={12} className='revenue-chart-div'>
            {regLoginChartLoader ? <div className='loader-div'><Loader size={34} /></div> : renderRegistrationLoginsChart()}
          </Grid.Col>
        </Grid>
      </Paper>

      {/* Practitioner Login History section */}
      <Paper m="lg" p="xs" className='paper-box'>
        <Grid className='grid-layout'>
          <Grid.Col span={6}>
            <Text align="left" size={18} fw={600} ml={10} pb={10}>Practitioner Login History</Text>
          </Grid.Col>
          <Grid.Col span={6} sx={{ textAlign: 'end' }}>
            <Button className='download-btn'>
              <img src="../../../../img/dashboard/download.svg" alt="Download" />
              <Text pl={5} size={14} color='#344054' fw={600}>Download Report</Text>
            </Button>
          </Grid.Col>
        </Grid>
        <Grid className='sub-options-div'>
          <Grid.Col span={5} className='end-align'>
            <Group className='sub-search-icon'>
              {/* Show Practitioner Search */}
              {renderPractitionerLoginHistorySearch()}
            </Group>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col lg={12} sx={{ overflow: 'auto' }}>
            {loginTableLoader ? (
              <div className='loader-div'><Loader size={34} /></div>
            ) : (
              <Table className='table-structure'>
                <LoginTableHeaders />
                <LoginTableRows data={loginAttemptsData} />
              </Table>
            )}
          </Grid.Col>
        </Grid>
        <Grid justify="center" mt="md" mb={6}>
          <Button
            onClick={() => handleLoginAttemptsPageChange('prev')}
            disabled={loginLoadingPrev || loginAttemptsOffset === 0 || loginLoadingNext}
            className='pagination-btn'
          >
            {loginLoadingPrev ? <Loader size="xs" /> : 'Previous'}
          </Button>
          <Button
            onClick={() => handleLoginAttemptsPageChange('next')}
            disabled={loginLoadingPrev || loginLoadingNext || loginAttemptsData.length < loginAttemptsPerPage || loginTableLoader}
            className='pagination-btn'
          >
            {loginLoadingNext ? <Loader size="xs" /> : 'Next'}
          </Button>
        </Grid>
      </Paper>
      {/* Login attempts by Practitioner section end*/}

      {/* Activity by Practitioners section */}
      <Paper m="lg" p="xs" className='paper-box'>
        <Grid className='grid-layout'>
          <Grid.Col span={6}>
            <Text align="left" size={18} fw={600} ml={10} pb={10}>Activity by Practitioners</Text>
          </Grid.Col>
          <Grid.Col span={6} sx={{ textAlign: 'end' }}>
            <Button className='download-btn'>
              <img src="../../../../img/dashboard/download.svg" alt="Download" />
              <Text pl={5} size={14} color='#344054' fw={600}>Download Report</Text>
            </Button>
          </Grid.Col>
        </Grid>
        <Grid className='pract-actions'>
          <Grid.Col span={3}>
            <AsyncAutocomplete
              size="sm"
              radius="md"
              icon={<IconSearch size={20} />}
              placeholder={'Search Practitioner'}
              disabled={activityTableLoading}
              toKey={toKey}
              toOption={toOption}
              onChange={handlePractitionerSearch}
              loadOptions={practitionerSearchloadData}
              maxSelectedValues={0}
              clearSearchOnChange
              clearable={true}
              mt={5}
              sx={{
                input: {
                  width: '200px',
                  paddingTop: '6px',
                  marginTop: '-3px',
                  transition: 'none',
                  '&:focus-within': {
                    width: '200px',
                  },
                },
              }}
            />
          </Grid.Col>
          <Grid.Col span={4} className='filter-div' pt={4}>
            <ErrorStatusFilter
              value={activityTableFilter}
              onChange={handleActivityFilterChange}
              disabled={activityTableLoading}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col lg={12} sx={{ overflow: 'auto' }}>
            {activityTableLoading ? (
              <div className='loader-div'><Loader size={34} /></div>
            ) : (
              <Table className='feedback_table'>
                <PractitionerActivityTable />
                <PractitionerActivityTableRows data={practitionerActivityData} />
              </Table>
            )}
          </Grid.Col>
        </Grid>
        <Grid justify="center" mt="md" mb={6}>
          <Button
            onClick={() => handlePractitionerActivityPageChange('prev')}
            disabled={loadingPrevPractitionerActivity || loadingNextPractitionerActivity || practitionerActivityOffset === 0}
            className='pagination-btn'
          >
            {loadingPrevPractitionerActivity ? <Loader size="xs" /> : 'Previous'}
          </Button>
          <Button
            onClick={() => handlePractitionerActivityPageChange('next')}
            disabled={loadingPrevPractitionerActivity || loadingNextPractitionerActivity || practitionerActivityData.length < practitionerActivityPerPage}
            className='pagination-btn'
          >
            {loadingNextPractitionerActivity ? <Loader size="xs" /> : 'Next'}
          </Button>
        </Grid>
      </Paper>
    </Box>
  );
}

export default UserActivity
